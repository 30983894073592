let planningAuthorityMultiplier = [
    { name: 'Pune Municipal Corporation', multiplier: 1.0 },
    { name: 'Thane Municipal Corporation', multiplier: 1.0 },
    { name: 'Pimpri Chinchwad Municipal Corporation', multiplier: 1.0 },
    { name: 'Nagpur Municipal Corporation', multiplier: 0.9 },
    { name: 'Nagpur TOD', multiplier: 1.0 },
    { name: 'Nasik Municipal Corporation', multiplier: 0.9 },
    { name: 'Municipal Corporation in MMR Area (Except Thane)', multiplier: 0.8 },
    { name: 'Any Other A,B,C Class Municipal Corporation', multiplier: 0.7 },
    { name: 'Area Development Authority', multiplier: 0.7 },
    { name: 'SPA (Special Planning Authority)', multiplier: 0.7 },
    { name: 'D Class Municipal Corporation', multiplier: 0.6 },
    { name: 'A Class Municipal Council', multiplier: 0.6 },
    { name: 'B, C Class Municipal Council', multiplier: 0.5 },
    { name: 'Nagar Panchayat', multiplier: 0.4 },
    { name: 'Any Other Non-Municipal Town / Regional Plan Area', multiplier: 0.4 },
]

let useValues = [
    { 
        use: 'Residential Units', subType: '0 to 30 SqM (0-322 SqFt)', unitText: 'No of Units', 
        divisible: 2, visitor: 5, conjestedCarFactor: 0, conjestedScooterFactor: 2,
        nonConjestedCarFactor: 0, nonConjestedScooterFactor: 2
    },
    { 
        use: 'Residential Units', subType: '30 to 40 SqM (320-430 SqFt)', unitText: 'No of Units', 
        divisible: 2, visitor: 5, conjestedCarFactor: 1, conjestedScooterFactor: 1,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 2
    },
    { 
        use: 'Residential Units', subType: '40 to 80 SqM (430-860 SqFt)', unitText: 'No of Units', 
        divisible: 2, visitor: 5, conjestedCarFactor: 1, conjestedScooterFactor: 2,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 2
    },
    { 
        use: 'Residential Units', subType: '80 to 150 SqM (860-1615 SqFt)', unitText: 'No of Units', 
        divisible: 1, visitor: 5, conjestedCarFactor: 1, conjestedScooterFactor: 1,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 1
    },
    { 
        use: 'Residential Units', subType: 'Above 150 SqM (>1615 SqFt)', unitText: 'No of Units', 
        divisible: 1, visitor: 5, conjestedCarFactor: 2, conjestedScooterFactor: 1,
        nonConjestedCarFactor: 2, nonConjestedScooterFactor: 1
    },
    { 
        use: 'Commercial Use', subType: 'Shops', unitText: 'Carpet Area', 
        divisible: 100, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 6,
        nonConjestedCarFactor: 2, nonConjestedScooterFactor: 6
    },
    { 
        use: 'Commercial Use', subType: 'Shops - Whole Sale Only', unitText: 'Carpet Area', 
        divisible: 100, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 4,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 5
    },
    { 
        use: 'Commercial Use', subType: 'Hazardous Building', unitText: 'Carpet Area', 
        divisible: 100, visitor: 0, conjestedCarFactor: 0, conjestedScooterFactor: 4,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 3
    },
    { 
        use: 'Commercial Use', subType: 'Offices', unitText: 'Carpet Area', 
        divisible: 200, visitor: 0, conjestedCarFactor: 3, conjestedScooterFactor: 11,
        nonConjestedCarFactor: 3, nonConjestedScooterFactor: 11
    },
    { 
        use: 'Commercial Use', subType: 'IT building Offices', unitText: 'Carpet Area', 
        divisible: 200, visitor: 0, conjestedCarFactor: 3, conjestedScooterFactor: 11,
        nonConjestedCarFactor: 3, nonConjestedScooterFactor: 11
    },
    { 
        use: 'Commercial Use', subType: 'Storage - Any Type', unitText: 'Carpet Area', 
        divisible: 300, visitor: 0, conjestedCarFactor: 0, conjestedScooterFactor: 4,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 3
    },
    { 
        use: 'Commercial Use', subType: 'Data Centre', unitText: 'Carpet Area', 
        divisible: 400, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 0,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 0
    },
    { 
        use: 'Commercial Use', subType: 'Private Business Building', unitText: 'Carpet Area', 
        divisible: 100, visitor: 20, conjestedCarFactor: 1, conjestedScooterFactor: 12,
        nonConjestedCarFactor: 2, nonConjestedScooterFactor: 12
    },
    { 
        use: 'Hotel', subType: 'Hotel - Guest Rooms', unitText: 'No of Rooms', 
        divisible: 5, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 4,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 6
    },
    { 
        use: 'Hotel', subType: 'Hotel - Dinning Area', unitText: 'Carpet Area', 
        divisible: 50, visitor: 0, conjestedCarFactor: 0, conjestedScooterFactor: 8,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 8
    },
    { 
        use: 'Educational', subType: 'School - Admin Area', unitText: 'Carpet Area', 
        divisible: 100, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 4,
        nonConjestedCarFactor: 2, nonConjestedScooterFactor: 4
    },
    { 
        use: 'Educational', subType: 'School - Classrooms', unitText: 'No of Classrooms', 
        divisible: 3, visitor: 0, conjestedCarFactor: 0, conjestedScooterFactor: 5,
        nonConjestedCarFactor: 0, nonConjestedScooterFactor: 5
    },
    { 
        use: 'Educational', subType: 'College - Admin Area', unitText: 'Carpet Area', 
        divisible: 100, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 12,
        nonConjestedCarFactor: 2, nonConjestedScooterFactor: 17
    },
    { 
        use: 'Educational', subType: 'College - Classrooms', unitText: 'No of Classrooms', 
        divisible: 3, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 24,
        nonConjestedCarFactor: 2, nonConjestedScooterFactor: 24
    },
    { 
        use: 'Educational', subType: 'Any Coaching Classes', unitText: 'No of Students', 
        divisible: 20, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 9,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 9
    },
    { 
        use: 'Medical Institutions', subType: 'Health Club / Clinic', unitText: 'No of Beds', 
        divisible: 10, visitor: 0, conjestedCarFactor: 2, conjestedScooterFactor: 12,
        nonConjestedCarFactor: 3, nonConjestedScooterFactor: 10
    },
    { 
        use: 'Medical Institutions', subType: 'Maternity Homes', unitText: 'No of Beds', 
        divisible: 10, visitor: 0, conjestedCarFactor: 2, conjestedScooterFactor: 12,
        nonConjestedCarFactor: 3, nonConjestedScooterFactor: 10
    },
    { 
        use: 'Medical Institutions', subType: 'Hospital', unitText: 'No of Beds', 
        divisible: 10, visitor: 0, conjestedCarFactor: 2, conjestedScooterFactor: 12,
        nonConjestedCarFactor: 3, nonConjestedScooterFactor: 10
    },
    { 
        use: 'Medical Institutions', subType: 'Any Other Medical Building', unitText: 'No of Beds', 
        divisible: 10, visitor: 0, conjestedCarFactor: 2, conjestedScooterFactor: 12,
        nonConjestedCarFactor: 3, nonConjestedScooterFactor: 10
    },
    { 
        use: 'Other Building', subType: 'Government / Semi Public Building', unitText: 'Carpet Area', 
        divisible: 100, visitor: 20, conjestedCarFactor: 1, conjestedScooterFactor: 12,
        nonConjestedCarFactor: 2, nonConjestedScooterFactor: 12
    },
    { 
        use: 'Other Building', subType: 'Industrial Building', unitText: 'Carpet Area', 
        divisible: 300, visitor: 0, conjestedCarFactor: 2, conjestedScooterFactor: 9,
        nonConjestedCarFactor: 3, nonConjestedScooterFactor: 9
    },
    { 
        use: 'Other Building', subType: 'Theatres, Auditoriums', unitText: 'No of Seats', 
        divisible: 40, visitor: 0, conjestedCarFactor: 4, conjestedScooterFactor: 16,
        nonConjestedCarFactor: 4, nonConjestedScooterFactor: 16
    },
    { 
        use: 'Other Building', subType: 'Multiplexes', unitText: 'No of Seats', 
        divisible: 40, visitor: 0, conjestedCarFactor: 5, conjestedScooterFactor: 14,
        nonConjestedCarFactor: 5, nonConjestedScooterFactor: 14
    },
    { 
        use: 'Other Building', subType: 'Mangal Karyalaya / Marriage Halls, Cultural Halls and Banquet Hall', unitText: 'Carpet / Lawn Area', 
        divisible: 100, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 5,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 5
    },
    { 
        use: 'Other Building', subType: 'Community Hall', unitText: 'Carpet Area', 
        divisible: 200, visitor: 0, conjestedCarFactor: 1, conjestedScooterFactor: 5,
        nonConjestedCarFactor: 1, nonConjestedScooterFactor: 5
    },
]

let nagpurUseValues = [
    { 
        use: 'Residential Units', subType: '25 to 40 SqM (269-430 SqFt)', unitText: 'No of Units', 
        divisible: 1, carFactor: 0, scooterFactor: 1, cycleFactor: 2
    },
    { 
        use: 'Residential Units', subType: '40 to 60 SqM (460-645 SqFt)', unitText: 'No of Units', 
        divisible: 2, carFactor: 1, scooterFactor: 1, cycleFactor: 2
    },
    { 
        use: 'Residential Units', subType: '60 to 80 SqM (645-861 SqFt)', unitText: 'No of Units', 
        divisible: 1, carFactor: 1, scooterFactor: 1, cycleFactor: 2
    },
    { 
        use: 'Residential Units', subType: 'Above 80 SqM (>861 SqFt)', unitText: 'No of Units', 
        divisible: 1, carFactor: 1, scooterFactor: 2, cycleFactor: 1
    },
    { 
        use: 'Commercial Use', subType: 'Private Business Building', unitText: 'Carpet Area', 
        divisible: 100, carFactor: 1, scooterFactor: 2, cycleFactor: 2
    },
    { 
        use: 'Commercial Use', subType: 'Government / Semi Public Building', unitText: 'Carpet Area', 
        divisible: 100, carFactor: 1, scooterFactor: 2, cycleFactor: 2
    },
]

export {
    useValues,
    nagpurUseValues,
    planningAuthorityMultiplier
};