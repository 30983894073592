import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { UseOfBuilding, SideMarginByRoadWidth, IndustrialSideMarginByPlotArea, RoadMarginByRoadWidth, CongestedRoadMargin } from '../../core/models/sideMarginTable';
import Swal from 'sweetalert2';
import { Meta, Title } from '@angular/platform-browser';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AppComponent } from '../../app.component';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/services/auth.service';
import { SeoService } from '../../core/services/seo.service';
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import { initTooltips, Tooltip, TooltipInterface, TooltipOptions } from 'flowbite';
import { environment } from '../../../environments/environment';
import { GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-side-margin',
  standalone: true,
  imports: [CommonModule, FormsModule, NgOtpInputModule, RouterModule, GoogleSigninButtonModule],
  templateUrl: './side-margin.component.html',
  styleUrls: ['./side-margin.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})

export class SideMarginComponent implements OnChanges {
  @Input() service = "";
  @Input() projectId = "";
  @Input() calId = "";
  @Input() isModal = false;
  @Input() edit = false;
  @Input() vendorId = "";
  @Input() directSendProposal = false;

  error = false;
  errorMessage: String = "";
  errorCancelAble = false;

  useOfBuildings = UseOfBuilding;

  @Output() closeModal = new EventEmitter<string>();

  userLoggedIn = false;
  serviceDetails: any = [];
  questions: any = [];
  groupedServices: any = [];
  searchGroupedServices: any = [];
  form!: FormGroup;
  totalQuestionCount = 0;
  stepForm = 0;
  currentStep = 1;

  name = "";
  email = "";
  mobile: any;

  orderId: any = "";
  isLoading = false;
  otp = "";
  otpValue = "";
  isOTP = false;
  seconds = 59;
  isResend = false;
  isProject = false;
  Projects = false;

  isNext = false;

  isResult = false;
  selectedItem: any = [];

  projects: any = [];
  projectName: any = "";
  editedName = "";
  NewProject = "";
  newProjectStatus = false;
  currentProjectId: any;
  newProjectName = "";

  //side margin
  plotArea: any = 0;
  plotAreaUnit: any = "Sq. M.";
  locality: any = "Non congested";
  planningAuthority: any = "Mahanagar Palika";
  useOfBuilding: any = "Any Residential Building";
  heightFromGround: any = 0;
  heightFromGroundUnit: any = "Meter";
  heightExclusiveParking: any = 0;
  heightExclusiveParkingUnit: any = "Meter";
  heightExcludingParking: any = 0;
  heightExcludingParkingUnit: any = "Meter";
  maxAreaSingleFloor: any = 0;
  maxAreaSingleFloorUnit: any = "Sq. M.";
  side1: any = {
    adjPlot: "",
    subType: "",
    roadWidth: 0,
    sideMargin: 0,
  };
  side2: any = {
    adjPlot: "",
    subType: "",
    roadWidth: 0,
    sideMargin: 0,
  };
  side3: any = {
    adjPlot: "",
    subType: "",
    roadWidth: 0,
    sideMargin: 0,
  };
  side4: any = {
    adjPlot: "",
    subType: "",
    roadWidth: 0,
    sideMargin: 0,
  }

  //calculation fields
  resultS1: any = "";
  resultS2: any = "";
  resultS3: any = "";
  resultS4: any = "";
  resultS5: any = "";
  heightExtraMargin15m: any = "";
  commercialBuildingFor3M: any = "";
  congestedSideMargin: any = "";
  nonCongestedSideMargin: any = "";
  finalSideMargin: any = "";

  congestedRoadMarginSide1: any = "";
  nonCongestedRoadMarginSide1: any = "";
  finalRoadMarginSide1: any = "";
  congestedRoadMarginSide2: any = "";
  nonCongestedRoadMarginSide2: any = "";
  finalRoadMarginSide2: any = "";
  congestedRoadMarginSide3: any = "";
  nonCongestedRoadMarginSide3: any = "";
  finalRoadMarginSide3: any = "";
  congestedRoadMarginSide4: any = "";
  nonCongestedRoadMarginSide4: any = "";
  finalRoadMarginSide4: any = "";
  finalRoadMargin: any = "";
  layoutOpenSpaceMargin: any = "";

  //output
  typeOfBuilding: any = "";
  maximumRoadWidth: any = "";

  isSendOTP = false;

  printLoader = false;
  baseURL = environment.baseURL;

  isGoogleAuth: any = false;
  isGoogleAuthRegister: any = false;
  isGoogleAuthUserData: any = [];
  isGoogleAuthSendOTP = false;
  isOTPModal = false;
  isSendOTPModal = false;
  isLoadingModal = false;
  isResendModal = false;
  googleUser: any = {};

  errorModal = false;
  errorMessageModal: String = "";
  errorCancelAbleModal = false;
  constructor(public api: ApiService, public auth: AuthService, public router: Router, public appC: AppComponent, private titleService: Title, private seoService: SeoService, private metaService: Meta,
    @Inject(DOCUMENT) public document: Document, public toastr: ToastrService, @Inject(PLATFORM_ID) private platformId: Object, public authService: SocialAuthService,) {
    this.titleService.setTitle(this.seoService.getTitle("side-margin"));
    this.metaService.addTags(this.seoService.getSEOMetaData("side-margin"));

    if (this.auth.currentUserValue) {
      this.userLoggedIn = true;
      this.name = this.auth.currentUserValue.name;
      this.email = this.auth.currentUserValue.email;
      this.mobile = this.auth.currentUserValue.mobile;
    }
    if (isPlatformBrowser(this.platformId)) {
      initTooltips();
    }
  }

  ngOnChanges() {
    this.authService.authState.subscribe(async (user) => {
      this.isGoogleAuth = true;
      if (user) {
        this.googleUser = user;
        let userData = (await this.api.getUser({ email: user.email }, 1, 1, "").toPromise()).data;
        if (userData.length > 0) {
          if (userData[0].mobile) {
            let data: any = { id: userData[0]._id, name: userData[0].name, email: userData[0].email, mobile: userData[0].mobile, walletBalance: userData[0].walletBalance, token: user.idToken, photo: userData[0].photo, userCode: userData[0].userCode, newUser: userData[0].newUser, status: userData[0].status }
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.auth.currentUserSubject.next(data);
            this.appC._fetchData();
            this.userLoggedIn = true;
            if (!this.projectId) {
              this.isProject = true;
              this.api.getProject({ user: userData[0]._id }, 1, 10000, "").subscribe((data: any) => {
                this.projects = data.data;
                this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
                this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
              })
            } else {
              this.calculate();
            }
          } else {
            this.isGoogleAuthRegister = true;
            this.isGoogleAuthUserData = userData[0];
          }
        } else {
        }
      }
    });
    if (this.calId) {
      this.api.getSideMargin({ _id: this.calId }, 1, 20, "").subscribe(data => {
        if (data.data) {
          this.plotArea = data.data[0].plotArea;
          this.plotAreaUnit = data.data[0].plotAreaUnit;
          this.plotArea = data.data[0].plotArea;
          this.plotAreaUnit = data.data[0].plotAreaUnit;
          this.locality = data.data[0].locality;
          this.planningAuthority = data.data[0].planningAuthority;
          this.useOfBuilding = data.data[0].useOfBuilding;
          this.heightFromGround = data.data[0].heightFromGround;
          this.heightFromGroundUnit = data.data[0].heightFromGroundUnit;
          this.heightExclusiveParking = data.data[0].heightExclusiveParking;
          this.heightExclusiveParkingUnit = data.data[0].heightExclusiveParkingUnit;
          this.heightExcludingParking = data.data[0].heightExcludingParking;
          this.heightExcludingParkingUnit = data.data[0].heightExcludingParkingUnit;
          this.maxAreaSingleFloor = data.data[0].maxAreaSingleFloor;
          this.maxAreaSingleFloorUnit = data.data[0].maxAreaSingleFloorUnit;
          this.side1 = data.data[0].side1;
          this.side2 = data.data[0].side2;
          this.side3 = data.data[0].side3;
          this.side4 = data.data[0].side4;
          this.projectId = data.data[0].project?._id;
          this.projectName = data.data[0].project?.title;
        }
      })
    }
  }

  mobileCheckModal(event: any) {
    this.clearError();
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isGoogleAuthSendOTP = true;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showErrorModal("You are a Foot2Feet Partner! Please use mobile application or registered as a new user!", true);
          }
          if (us.data[0].role == "user" && us.data[0]._id != this.isGoogleAuthUserData._id && this.isGoogleAuthRegister) {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
          if (us.data[0].role == "user") {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
        }
      });
    } else {
      this.isGoogleAuthSendOTP = false;
    }

  }

  sendOTPModal() {
    const data = JSON.stringify({
      "mobile": this.mobile,
      "role": "user",
      "isPlatform": "website",
      "isGoogleAuth": true
    });
    this.api.sendOTP(data).subscribe(res => {
      if (res.status) {
        this.orderId = res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }

  resendOTPModal() {
    const data = JSON.stringify({
      "orderId": this.orderId
    });
    this.api.resendOTP(data).subscribe(res => {
      if (res.status) {
        // this.orderId =res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }


  none() { }

  showErrorModal(message: String, cancelAble = false) {
    this.errorModal = true;
    this.errorMessageModal = message;
    this.errorCancelAbleModal = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 10000);
    }

  }

  clearErrorModal() {
    this.errorModal = false;
    this.errorMessageModal = "";
    this.errorCancelAbleModal = false;
  }

  onMobileInputModal(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  nextStep() {
    this.isNext = true;
    if (this.currentStep == 1 && this.plotArea != '' && this.useOfBuilding != '' && this.heightFromGround != '' && this.heightExcludingParking != '') {
      this.currentStep++;
      this.isNext = false;
    }

  }

  AddProject() {
    this.Projects = true;
  }


  createdNewProject() {
    this.newProjectStatus = true;
    if (!this.newProjectName) {
      this.toastr.error("Enter a project name");
      this.newProjectStatus = false;
      return;
    }
    const data = JSON.stringify({
      title: this.newProjectName,
      user: this.auth.currentUserValue.id,
    })
    this.api.createProject(data).subscribe((cdata: any) => {
      if (cdata.status) {
        this.Projects = false;
        this.projects.push({
          "_id": cdata.data._id,
          "title": this.newProjectName,
          "isDefault": false,
          "user": this.auth.currentUserValue,
          "createdAt": new Date()
        });
        this.newProjectName = "";
        this.newProjectStatus = false;
      } else {
        this.toastr.error(cdata.message);
        this.newProjectStatus = false;
      }
    }, error => {
      this.toastr.error(error.message);
      this.newProjectStatus = false;
    })
  }

  editProject(item: any, i: any) {
    item.editProjects = true;
    this.currentProjectId = item._id;
    setTimeout(() => {
      this.document.getElementById('input' + i)?.focus();
    }, 200);


  }

  editProjectName(item: any) {
    const data = JSON.stringify({
      title: item.title
    })
    this.api.updateProject(data, this.currentProjectId).subscribe((cdata: any) => {
      if (cdata.status) {
        item.editProjects = false;
      } else {
        this.toastr.error(cdata.message);
        this.newProjectStatus = false;
      }
    }, error => {
      this.toastr.error(error.message);
      this.newProjectStatus = false;
    })
  }

  getProjectName(event: any, def: any) {
    if (def) {
      this.projectName = "My project";
    } else {
      this.projectName = this.projects[Number(event.target.id)].title;
    }
  }

  nextSlide() {
    if (this.userLoggedIn) {
      this.isProject = true;
      this.api.getProject({ user: this.auth.currentUserValue.id }, 1, 10000, "").subscribe((data: any) => {
        this.projects = data.data;
        this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
        this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
      })
    } else {
      // if(this.otp == this.otpValue){
      if (this.isGoogleAuth) {

        this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {

          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();
          this.userLoggedIn = true;
          this.isProject = true;
          this.api.getProject({ user: res.data.id }, 1, 10000, "").subscribe((data: any) => {
            this.projects = data.data;
            this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
            this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
          })
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true);
        });
      } else {
        this.api.authenticateUser({ name: this.name, mobile: this.mobile, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {

          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();
          this.userLoggedIn = true;
          this.isProject = true;
          this.api.getProject({ user: res.data.id }, 1, 10000, "").subscribe((data: any) => {
            this.projects = data.data;
            this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
            this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
          })
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true);
        });
      }
      // }else{
      //   this.isLoading = false;
      //   this.showError("Incorrect OTP",true);
      // }
    }
  }

  backStep() {
    this.currentStep--;
  }

  changeHeightFromGround(event: any) {
    let heightExclusive = 0;
    let heightFromGround = 0;
    if (this.heightExclusiveParkingUnit == 'Feet') {
      heightExclusive = parseFloat((this.heightExclusiveParking * 0.3048).toFixed(2))
    } else {
      heightExclusive = this.heightExclusiveParking
    }

    if (this.heightFromGroundUnit == 'Feet') {
      heightFromGround = parseFloat((event.target.value * 0.3048).toFixed(2))
    } else {
      heightFromGround = event.target.value
    }
    this.heightExcludingParking = (heightFromGround * 1) - Math.min((heightExclusive * 1), 6);
  }

  changeHeightFromGroundUnit(event: any) {
    let heightExclusive = 0;
    let heightFromGround = 0;
    if (this.heightExclusiveParkingUnit == 'Feet') {
      heightExclusive = parseFloat((this.heightExclusiveParking * 0.3048).toFixed(2))
    } else {
      heightExclusive = this.heightExclusiveParking
    }

    if (event.target.value == 'Feet') {
      heightFromGround = parseFloat((this.heightFromGround * 0.3048).toFixed(2))
    } else {
      heightFromGround = this.heightFromGround
    }
    this.heightExcludingParking = (heightFromGround * 1) - Math.min((heightExclusive * 1), 6);
  }

  changeHeightExclusive(event: any) {
    let heightExclusive = 0;
    let heightFromGround = 0;
    if (this.heightFromGroundUnit == 'Feet') {
      heightFromGround = parseFloat((this.heightFromGround * 0.3048).toFixed(2))
    } else {
      heightFromGround = this.heightFromGround
    }

    if (this.heightExclusiveParkingUnit == 'Feet') {
      heightExclusive = parseFloat((event.target.value * 0.3048).toFixed(2))
    } else {
      heightExclusive = event.target.value
    }
    this.heightExcludingParking = (heightFromGround * 1) - Math.min((heightExclusive * 1), 6);
  }

  changeHeightExclusiveUnit(event: any) {
    let heightExclusive = 0;
    let heightFromGround = 0;
    if (this.heightFromGroundUnit == 'Feet') {
      heightFromGround = parseFloat((this.heightFromGround * 0.3048).toFixed(2))
    } else {
      heightFromGround = this.heightFromGround
    }

    if (event.target.value == 'Feet') {
      heightExclusive = parseFloat((this.heightExclusiveParking * 0.3048).toFixed(2))
    } else {
      heightExclusive = this.heightExclusiveParking
    }
    this.heightExcludingParking = (heightFromGround * 1) - Math.min((heightExclusive * 1), 6);
  }

  sendOTP() {
    if (this.isGoogleAuth) {
      this.isNext = true;
      const data = JSON.stringify({
        "mobile": this.mobile,
        "role": "user",
        "isPlatform": "website",
        "isGoogleAuth": true
      });
      this.isLoadingModal = true;
      if (this.isSendOTP && this.name != '' && this.email != '') {
        this.isNext = false;
      }
      this.api.sendOTP(data).subscribe(res => {
        if (res.status) {
          this.orderId = res.data.orderId;
          // this.otp = res.data.otp;
          this.isOTPModal = true;
          this.isLoadingModal = false;
          this.countdown();
        } else {
          this.isLoadingModal = false;
          this.showError(res.message, true);
        }
      }, error => {
        this.isLoadingModal = false;

        this.showError(error.error.error.message, true);
      });
    } else {
      this.isNext = true;
      const data = JSON.stringify({
        "mobile": this.mobile,
        "role": "user",
        "name": this.name,
        "email": this.email,
        "isPlatform": "website"
      });
      this.isLoading = true;
      if (this.isSendOTP && this.name != '' && this.email != '') {
        this.isNext = false;
      }
      this.api.sendOTP(data).subscribe(res => {
        if (res.status) {
          this.orderId = res.data.orderId;
          // this.otp = res.data.otp;
          this.isOTP = true;
          this.isLoading = false;
          this.countdown();
        } else {
          this.isLoading = false;
          this.showError(res.message, true);
        }
      }, error => {
        this.isLoading = false;

        this.showError(error.error.error.message, true);
      });
    }
  }

  navigate() {
    if (this.isModal) {
      this.closeModal.emit('close');
    } else {
      this.router.navigate(['/project', this.projectId])
    }
  }

  mobileCheck(event: any) {
    this.isLoading = true;
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isSendOTP = true;
          this.isLoading = false;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showError("This Mobile No is assosiated with Partner Profile. Please try another mobile no to access website.");
            this.isSendOTP = false;
            this.isLoading = false;
          } else {
            this.isSendOTP = false;
            this.isLoading = false;
          }
        }
      });
    } else {
      this.isSendOTP = false;
      this.isLoading = false;
    }

  }

  showError(message: String, cancelAble = false) {
    this.error = true;
    this.errorMessage = message;
    this.errorCancelAble = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 4000);
    }

  }

  otpEnterd(event: any) {
    if (event.length == 6) {
      this.otpValue = event;
    }
  }

  onMobileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  countdown() {
    this.seconds = 59;
    let interval = setInterval(() => {
      this.seconds--;

      if (this.seconds <= 0) {
        clearInterval(interval);
        this.isResend = true;
      }
    }, 1000);
  }

  onSubmit() {
    if (this.userLoggedIn) {
      if (this.currentStep == 2) {
        this.isNext = true;

        let allConditionsMet = true;

        for (let i = 1; i <= 4; i++) {
          let currentSide: any;
          if (i == 1) {
            currentSide = this.side1;
          } else if (i == 2) {
            currentSide = this.side2;
          } else if (i == 3) {
            currentSide = this.side3;
          } else {
            currentSide = this.side4;
          }
          if (currentSide.adjPlot != '') {
            if (((currentSide.adjPlot == 'Road' && (currentSide.subType == '' || currentSide.roadWidth == '')) ||
              (currentSide.adjPlot == 'Reservation Land' && currentSide.subType == ''))) {
              allConditionsMet = false;
            }
          }
          if (currentSide.adjPlot == '') {
            allConditionsMet = false;
          }
          if (i == 4) {
            if (allConditionsMet) {
              this.isNext = false;
              this.currentStep++;
            }
          }
        }
      } else {
        this.calculate();
      }
    } else {
      if (this.currentStep == 2) {
        this.isNext = true;

        let allConditionsMet = true;

        for (let i = 1; i <= 4; i++) {
          let currentSide: any;
          if (i == 1) {
            currentSide = this.side1;
          } else if (i == 2) {
            currentSide = this.side2;
          } else if (i == 3) {
            currentSide = this.side3;
          } else {
            currentSide = this.side4;
          }
          if (currentSide.adjPlot != '') {
            if (((currentSide.adjPlot == 'Road' && (currentSide.subType == '' || currentSide.roadWidth == '')) ||
              (currentSide.adjPlot == 'Reservation Land' && currentSide.subType == ''))) {
              allConditionsMet = false;
            }
          }
          if (currentSide.adjPlot == '') {
            allConditionsMet = false;
          }
          if (i == 4) {
            if (allConditionsMet) {
              this.isNext = false;
              this.currentStep++;
            }
          }
        }
      } else {
        // if(this.otp == this.otpValue){
        if (this.isGoogleAuth) {
          this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {

            localStorage.setItem('currentUser', JSON.stringify(res.data));
            this.auth.currentUserSubject.next(res.data);
            this.appC._fetchData();
            this.calculate();
          }, error => {
            this.isLoading = false;
            this.showError(error.error.error.message, true);
          });
        } else {
          this.api.authenticateUser({ name: this.name, mobile: this.mobile, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {

            localStorage.setItem('currentUser', JSON.stringify(res.data));
            this.auth.currentUserSubject.next(res.data);
            this.appC._fetchData();
            this.calculate();
          }, error => {
            this.isLoading = false;
            this.showError(error.error.error.message, true);
          });
        }
        // }else{
        //   this.isLoading = false;
        //   this.showError("Incorrect OTP",true);
        // }
      }
    }
  }

  clearError() {
    this.error = false;
    this.errorMessage = "";
    this.errorCancelAble = false;
  }

  sideMarginCalculateData() {

    //Conversion
    let plotAreaSqm: any;
    let heightFromGroundMeter: any;
    let heightExclusiveParkingMeter: any;
    let heightExcludingParkingMeter: any;
    let maxAreaSingleFloorSqm: any;

    (this.plotAreaUnit == 'Sq. M.') ? plotAreaSqm = this.plotArea : plotAreaSqm = (this.plotArea / 10.764).toFixed(2);
    (this.heightFromGroundUnit == 'Meter') ? heightFromGroundMeter = this.heightFromGround : heightFromGroundMeter = (this.heightFromGround / 3.28).toFixed(2);
    (this.heightExclusiveParkingUnit == 'Meter') ? heightExclusiveParkingMeter = this.heightExclusiveParking : heightExclusiveParkingMeter = (this.heightExclusiveParking / 3.28).toFixed(2);
    (this.heightExcludingParkingUnit == 'Meter') ? heightExcludingParkingMeter = this.heightExcludingParking : heightExcludingParkingMeter = (this.heightExcludingParking / 3.28).toFixed(2);
    (this.maxAreaSingleFloorUnit == 'Sq. M.') ? maxAreaSingleFloorSqm = this.maxAreaSingleFloor : maxAreaSingleFloorSqm = (this.maxAreaSingleFloor / 10.764).toFixed(2);

    //Required Fields
    let industrialBuildingRoadMargin: any;
    let specialBuildingValue: any;
    let roadMarginByUseBuilding: any;
    let industrialMargin: any;

    (plotAreaSqm < 500) ? industrialBuildingRoadMargin = 3 : industrialBuildingRoadMargin = 4.5;
    UseOfBuilding.filter((res: any) => res.type == "Industrial Building")[0].roadMargin = industrialBuildingRoadMargin;
    specialBuildingValue = UseOfBuilding.filter((res: any) => res.type == this.useOfBuilding)[0].specialBldValue;
    roadMarginByUseBuilding = UseOfBuilding.filter((res: any) => res.type == this.useOfBuilding)[0].roadMargin;
    industrialMargin = IndustrialSideMarginByPlotArea.filter((res: any) => (res.start <= plotAreaSqm && res.end >= plotAreaSqm))[0].value;
    (specialBuildingValue == 1) ? this.typeOfBuilding = "Small Residences" : ((specialBuildingValue == 2) ? this.typeOfBuilding = "Residential Building" :
      ((specialBuildingValue == 2.5) ? this.typeOfBuilding = "Residential Mix Use" : ((specialBuildingValue == 3) ? this.typeOfBuilding = "Commercial Building" : this.typeOfBuilding = "Industrial Building")))

    //table s1
    this.maximumRoadWidth = Math.max((this.side1.roadWidth) ? this.side1.roadWidth : 0, (this.side2.roadWidth) ? this.side2.roadWidth : 0, (this.side3.roadWidth) ? this.side3.roadWidth : 0, (this.side4.roadWidth) ? this.side4.roadWidth : 0);
    this.resultS1 = SideMarginByRoadWidth.filter((res: any) => (res.start <= this.maximumRoadWidth && res.end >= this.maximumRoadWidth))[0].value;

    //table s2
    let heightMoreThan2399: any;
    let singleFloorArea750: any;
    let commercialIndustrial500: any;
    let isTheSpecialBuilding: any;

    (heightFromGroundMeter > 23.99) ? heightMoreThan2399 = "Yes" : heightMoreThan2399 = "No";
    (maxAreaSingleFloorSqm > 749.99) ? singleFloorArea750 = "Yes" : singleFloorArea750 = "No";
    (maxAreaSingleFloorSqm > 499.99 && specialBuildingValue > 2) ? commercialIndustrial500 = "Yes" : commercialIndustrial500 = "No";
    (heightMoreThan2399 == "Yes" || singleFloorArea750 == "Yes" || commercialIndustrial500 == "Yes") ? isTheSpecialBuilding = "Yes" : isTheSpecialBuilding = "No";
    (isTheSpecialBuilding == "Yes") ? this.resultS2 = 6 : this.resultS2 = 0;

    //layout open space margin
    this.layoutOpenSpaceMargin = (isTheSpecialBuilding == "Yes" || heightMoreThan2399 == "Yes") ? 6 : 3;

    //table s3
    let sideMarginByhs = [{ type: "Small Residences", value: 0 }, { type: "Residential Building", value: (heightExcludingParkingMeter / 5).toFixed(2) },
    { type: "Residential Max Use", value: (heightExcludingParkingMeter / 5).toFixed(2) }, { type: "Commercial Building", value: (heightExcludingParkingMeter / 5).toFixed(2) },
    { type: "Industrial Building", value: industrialMargin }]
    this.resultS3 = sideMarginByhs.filter((res: any) => (res.type == this.typeOfBuilding))[0].value;

    //table s4
    let sideMarginByUseBuilding = [{ type: "Small Residences", value: 1.5 }, { type: "Residential Building", value: 1.5 },
    { type: "Residential Max Use", value: 1.5 }, { type: "Commercial Building", value: 3 },
    { type: "Industrial Building", value: industrialMargin }]
    this.resultS4 = sideMarginByUseBuilding.filter((res: any) => (res.type == this.typeOfBuilding))[0].value;

    //noncongested side margin
    this.nonCongestedSideMargin = Math.min(Math.max(this.resultS1, this.resultS2, this.resultS3, this.resultS4), 12);

    //table s5
    let congestedSideMarginByPlotArea = [{ start: 0, end: 999.99, value: 0 }, { start: 1000, end: 3999.99, value: 1 },
    { start: 4000, end: 1000000, value: this.nonCongestedSideMargin }]
    this.resultS5 = congestedSideMarginByPlotArea.filter((res: any) => (res.start <= plotAreaSqm && res.end >= plotAreaSqm))[0].value;

    //table s6
    this.heightExtraMargin15m = (heightFromGroundMeter < 15) ? 0 : ((heightFromGroundMeter < 24) ? 1 : 0);

    //table s7
    this.commercialBuildingFor3M = (this.typeOfBuilding == "Commercial Building") ? 3 : 0;

    //congested side margin
    this.congestedSideMargin = Math.max(((this.resultS5 * 1) + (this.heightExtraMargin15m * 1)), this.resultS2, this.commercialBuildingFor3M);

    //Final side margin
    this.finalSideMargin = (this.locality == 'Congested') ? this.congestedSideMargin : this.nonCongestedSideMargin;

    switch (this.side1.adjPlot) {
      case "Road":
        //table r1
        let resultR1 = (this.planningAuthority == 'Mahanagar Palika') ? (RoadMarginByRoadWidth.filter((res: any) => ((res.start <= this.side1.roadWidth && res.end >= this.side1.roadWidth) && res.type == "Mahanagar Palika"))[0].value) : (RoadMarginByRoadWidth.filter((res: any) => ((res.start <= this.side1.roadWidth && res.end >= this.side1.roadWidth) && res.type != "Mahanagar Palika"))[0].value);

        //table r2
        let resultR2 = 0;

        //table r3
        let resultR3 = roadMarginByUseBuilding;

        //table r4
        let resultR4 = (this.useOfBuilding == "Row House" && this.maximumRoadWidth < 12.01) ? 2.25 : 0;

        //non congested road margin
        this.nonCongestedRoadMarginSide1 = Math.min(((resultR4 > 1) ? resultR4 : Math.max(resultR1, resultR2, resultR3)), 12);

        //table r5
        let resultR5Residential = CongestedRoadMargin.filter((res: any) => ((res.start <= this.side1.roadWidth && res.end >= this.side1.roadWidth) && res.type == "Residential Building"))[0].value;
        let resultR5ResidentialMixUse = CongestedRoadMargin.filter((res: any) => ((res.start <= this.side1.roadWidth && res.end >= this.side1.roadWidth) && res.type == "Residential Mix Use"))[0].value;

        //table r6
        let allOtherBuildings = 3;
        let resultR6 = (this.typeOfBuilding == "Residential Building") ? resultR5Residential : ((this.typeOfBuilding == "Residential Mix Use") ? resultR5ResidentialMixUse : allOtherBuildings);

        let useOfBuildingMargin = (roadMarginByUseBuilding > 3) ? roadMarginByUseBuilding : 0;
        //congested road margin
        this.congestedRoadMarginSide1 = (useOfBuildingMargin == 6) ? 6 : resultR6;

        //final road margin
        this.finalRoadMarginSide1 = (this.locality == 'Congested') ? this.congestedRoadMarginSide1 : this.nonCongestedRoadMarginSide1;
        this.side1.sideMargin = (this.locality == 'Congested') ? this.congestedRoadMarginSide1 : this.finalRoadMarginSide1;
        break;
      case "Other Property":
        this.side1.sideMargin = this.finalSideMargin;
        break;
      case "Layout Open Space":
        this.side1.sideMargin = this.layoutOpenSpaceMargin;
        break;
      case "Reservation Land":
        this.side1.sideMargin = this.finalSideMargin;
        break;
    }
    switch (this.side2.adjPlot) {
      case "Road":
        //table r1
        let resultR1 = (this.planningAuthority == 'Mahanagar Palika') ? (RoadMarginByRoadWidth.filter((res: any) => ((res.start <= this.side2.roadWidth && res.end >= this.side2.roadWidth) && res.type == "Mahanagar Palika"))[0].value) : (RoadMarginByRoadWidth.filter((res: any) => ((res.start <= this.side2.roadWidth && res.end >= this.side2.roadWidth) && res.type != "Mahanagar Palika"))[0].value);

        //table r2
        let resultR2 = 0;

        //table r3
        let resultR3 = roadMarginByUseBuilding;

        //table r4
        let resultR4 = (this.useOfBuilding == "Row House" && this.maximumRoadWidth < 12.01) ? 2.25 : 0;

        //non congested road margin
        this.nonCongestedRoadMarginSide2 = Math.min(((resultR4 > 1) ? resultR4 : Math.max(resultR1, resultR2, resultR3)), 12);

        //table r5
        let resultR5Residential = CongestedRoadMargin.filter((res: any) => ((res.start <= this.side2.roadWidth && res.end >= this.side2.roadWidth) && res.type == "Residential Building"))[0].value;
        let resultR5ResidentialMixUse = CongestedRoadMargin.filter((res: any) => ((res.start <= this.side2.roadWidth && res.end >= this.side2.roadWidth) && res.type == "Residential Mix Use"))[0].value;

        //table r6
        let allOtherBuildings = 3;
        let resultR6 = (this.typeOfBuilding == "Residential Building") ? resultR5Residential : ((this.typeOfBuilding == "Residential Mix Use") ? resultR5ResidentialMixUse : allOtherBuildings);

        let useOfBuildingMargin = (roadMarginByUseBuilding > 3) ? roadMarginByUseBuilding : 0;
        //congested road margin
        this.congestedRoadMarginSide2 = (useOfBuildingMargin == 6) ? 6 : resultR6;

        //final road margin
        this.finalRoadMarginSide2 = (this.locality == 'Congested') ? this.congestedRoadMarginSide2 : this.nonCongestedRoadMarginSide2;
        this.side2.sideMargin = (this.locality == 'Congested') ? this.congestedRoadMarginSide2 : this.finalRoadMarginSide2;
        break;
      case "Other Property":
        this.side2.sideMargin = this.finalSideMargin;
        break;
      case "Layout Open Space":
        this.side2.sideMargin = this.layoutOpenSpaceMargin;
        break;
      case "Reservation Land":
        this.side2.sideMargin = this.finalSideMargin;
        break;
    }
    switch (this.side3.adjPlot) {
      case "Road":
        //table r1
        let resultR1 = (this.planningAuthority == 'Mahanagar Palika') ? (RoadMarginByRoadWidth.filter((res: any) => ((res.start <= this.side3.roadWidth && res.end >= this.side3.roadWidth) && res.type == "Mahanagar Palika"))[0].value) : (RoadMarginByRoadWidth.filter((res: any) => ((res.start <= this.side3.roadWidth && res.end >= this.side3.roadWidth) && res.type != "Mahanagar Palika"))[0].value);

        //table r2
        let resultR2 = 0;

        //table r3
        let resultR3 = roadMarginByUseBuilding;

        //table r4
        let resultR4 = (this.useOfBuilding == "Row House" && this.maximumRoadWidth < 12.01) ? 2.25 : 0;

        //non congested road margin
        this.nonCongestedRoadMarginSide3 = Math.min(((resultR4 > 1) ? resultR4 : Math.max(resultR1, resultR2, resultR3)), 12);

        //table r5
        let resultR5Residential = CongestedRoadMargin.filter((res: any) => ((res.start <= this.side3.roadWidth && res.end >= this.side3.roadWidth) && res.type == "Residential Building"))[0].value;
        let resultR5ResidentialMixUse = CongestedRoadMargin.filter((res: any) => ((res.start <= this.side3.roadWidth && res.end >= this.side3.roadWidth) && res.type == "Residential Mix Use"))[0].value;

        //table r6
        let allOtherBuildings = 3;
        let resultR6 = (this.typeOfBuilding == "Residential Building") ? resultR5Residential : ((this.typeOfBuilding == "Residential Mix Use") ? resultR5ResidentialMixUse : allOtherBuildings);

        let useOfBuildingMargin = (roadMarginByUseBuilding > 3) ? roadMarginByUseBuilding : 0;
        //congested road margin
        this.congestedRoadMarginSide3 = (useOfBuildingMargin == 6) ? 6 : resultR6;

        //final road margin
        this.finalRoadMarginSide3 = (this.locality == 'Congested') ? this.congestedRoadMarginSide3 : this.nonCongestedRoadMarginSide3;
        this.side3.sideMargin = (this.locality == 'Congested') ? this.congestedRoadMarginSide3 : this.finalRoadMarginSide3;
        break;
      case "Other Property":
        this.side3.sideMargin = this.finalSideMargin;
        break;
      case "Layout Open Space":
        this.side3.sideMargin = this.layoutOpenSpaceMargin;
        break;
      case "Reservation Land":
        this.side3.sideMargin = this.finalSideMargin;
        break;
    }
    switch (this.side4.adjPlot) {
      case "Road":
        //table r1
        let resultR1 = (this.planningAuthority == 'Mahanagar Palika') ? (RoadMarginByRoadWidth.filter((res: any) => ((res.start <= this.side4.roadWidth && res.end >= this.side4.roadWidth) && res.type == "Mahanagar Palika"))[0].value) : (RoadMarginByRoadWidth.filter((res: any) => ((res.start <= this.side4.roadWidth && res.end >= this.side4.roadWidth) && res.type != "Mahanagar Palika"))[0].value);

        //table r2
        let resultR2 = 0;

        //table r3
        let resultR3 = roadMarginByUseBuilding;

        //table r4
        let resultR4 = (this.useOfBuilding == "Row House" && this.maximumRoadWidth < 12.01) ? 2.25 : 0;

        //non congested road margin
        this.nonCongestedRoadMarginSide4 = Math.min(((resultR4 > 1) ? resultR4 : Math.max(resultR1, resultR2, resultR3)), 12);

        //table r5
        let resultR5Residential = CongestedRoadMargin.filter((res: any) => ((res.start <= this.side4.roadWidth && res.end >= this.side4.roadWidth) && res.type == "Residential Building"))[0].value;
        let resultR5ResidentialMixUse = CongestedRoadMargin.filter((res: any) => ((res.start <= this.side4.roadWidth && res.end >= this.side4.roadWidth) && res.type == "Residential Mix Use"))[0].value;

        //table r6
        let allOtherBuildings = 3;
        let resultR6 = (this.typeOfBuilding == "Residential Building") ? resultR5Residential : ((this.typeOfBuilding == "Residential Mix Use") ? resultR5ResidentialMixUse : allOtherBuildings);

        let useOfBuildingMargin = (roadMarginByUseBuilding > 3) ? roadMarginByUseBuilding : 0;
        //congested road margin
        this.congestedRoadMarginSide4 = (useOfBuildingMargin == 6) ? 6 : resultR6;

        //final road margin
        this.finalRoadMarginSide4 = (this.locality == 'Congested') ? this.congestedRoadMarginSide4 : this.nonCongestedRoadMarginSide4;
        this.side4.sideMargin = (this.locality == 'Congested') ? this.congestedRoadMarginSide4 : this.finalRoadMarginSide4;
        break;
      case "Other Property":
        this.side4.sideMargin = this.finalSideMargin;
        break;
      case "Layout Open Space":
        this.side4.sideMargin = this.layoutOpenSpaceMargin;
        break;
      case "Reservation Land":
        this.side4.sideMargin = this.finalSideMargin;
        break;
    }
  }

  openCalculatorModal() {
    this.router.navigate(['/project', this.projectId], {
      queryParams: {
        id: this.selectedItem._id,
        calculator: 'Side Margin'
      }
    })
  }

  calculate() {
    this.isLoading = true;
    if (!this.projectId) {
      this.isNext = true;
      this.isLoading = false;
      return;
    }
    Swal.fire({
      title: "Calculating...",
      icon: 'success',
      timer: 1500,
      allowOutsideClick: false,
      showConfirmButton: false
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        this.sideMarginCalculateData();
        if (!this.edit) {
          // if(this.projects.length == 0){
          //   const pdata = JSON.stringify({
          //     title: "My Project",
          //     isDefault: true,
          //     user: this.auth.currentUserValue.id
          //   })
          //   this.api.createProject(pdata).subscribe((presData:any) => { 
          //     if(presData.status){
          //       this.projectId = presData.data._id;
          //       const data = JSON.stringify({
          //         plotArea:this.plotArea,
          //         plotAreaUnit:this.plotAreaUnit,
          //         locality:this.locality,
          //         planningAuthority:this.planningAuthority,
          //         useOfBuilding:this.useOfBuilding,
          //         heightFromGround:this.heightFromGround,
          //         heightFromGroundUnit:this.heightFromGroundUnit,
          //         heightExclusiveParking:this.heightExclusiveParking,
          //         heightExclusiveParkingUnit:this.heightExclusiveParkingUnit,
          //         heightExcludingParking:this.heightExcludingParking,
          //         heightExcludingParkingUnit:this.heightExcludingParkingUnit,
          //         maxAreaSingleFloor:this.maxAreaSingleFloor,
          //         maxAreaSingleFloorUnit:this.maxAreaSingleFloorUnit,
          //         side1:this.side1,
          //         side2:this.side2,
          //         side3:this.side3,
          //         side4:this.side4,
          //         user:this.auth.currentUserValue.id,
          //         project:this.projectId,
          //         typeOfBuilding:this.typeOfBuilding,
          //         maximumRoadWidth:this.maximumRoadWidth,
          //       })
          //       this.api.createSideMargin(data).subscribe(res=>{
          //         this.isLoading = false;
          //         this.closeModal.emit('close');
          //         this.isResult = true;
          //         this.selectedItem = res.data;
          //         // this.router.navigate(['/project',res.data.project]);
          //       })
          //     }
          //   });
          // }else{
          const data = JSON.stringify({
            plotArea: this.plotArea,
            plotAreaUnit: this.plotAreaUnit,
            locality: this.locality,
            planningAuthority: this.planningAuthority,
            useOfBuilding: this.useOfBuilding,
            heightFromGround: this.heightFromGround,
            heightFromGroundUnit: this.heightFromGroundUnit,
            heightExclusiveParking: this.heightExclusiveParking,
            heightExclusiveParkingUnit: this.heightExclusiveParkingUnit,
            heightExcludingParking: this.heightExcludingParking,
            heightExcludingParkingUnit: this.heightExcludingParkingUnit,
            maxAreaSingleFloor: this.maxAreaSingleFloor,
            maxAreaSingleFloorUnit: this.maxAreaSingleFloorUnit,
            side1: this.side1,
            side2: this.side2,
            side3: this.side3,
            side4: this.side4,
            user: this.auth.currentUserValue.id,
            project: this.projectId,
            typeOfBuilding: this.typeOfBuilding,
            maximumRoadWidth: this.maximumRoadWidth,
          })
          this.api.createSideMargin(data).subscribe(res => {
            this.isLoading = false;
            this.closeModal.emit('close');
            this.isResult = true;
            this.selectedItem = res.data;
            // this.router.navigate(['/project',res.data.project]);
          })
          // }
        } else {
          const data = JSON.stringify({
            plotArea: this.plotArea,
            plotAreaUnit: this.plotAreaUnit,
            locality: this.locality,
            planningAuthority: this.planningAuthority,
            useOfBuilding: this.useOfBuilding,
            heightFromGround: this.heightFromGround,
            heightFromGroundUnit: this.heightFromGroundUnit,
            heightExclusiveParking: this.heightExclusiveParking,
            heightExclusiveParkingUnit: this.heightExclusiveParkingUnit,
            heightExcludingParking: this.heightExcludingParking,
            heightExcludingParkingUnit: this.heightExcludingParkingUnit,
            maxAreaSingleFloor: this.maxAreaSingleFloor,
            maxAreaSingleFloorUnit: this.maxAreaSingleFloorUnit,
            side1: this.side1,
            side2: this.side2,
            side3: this.side3,
            side4: this.side4,
            typeOfBuilding: this.typeOfBuilding,
            maximumRoadWidth: this.maximumRoadWidth,
          })

          this.api.updateSideMargin(data, this.calId).subscribe(res => {
            this.isLoading = false;
            // this.router.navigate(['/project',this.projectId]);
            this.isResult = true;
            this.selectedItem = res.data;
          })
        }
      }
    });

  }

  sideDownload() {
    this.printLoader = true;
    this.api.downloadSideMargin(this.selectedItem._id).subscribe(data => {
      const link = document.createElement('a');
      link.href = this.baseURL + '/file/retrieve-pdf/' + data.data;
      link.target = '_blank';
      link.download = 'Side Margin Calculator.pdf'; // Set the desired filename
      link.click();
      this.printLoader = false;
    })
  }
}
