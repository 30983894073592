import { GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { FormsModule, FormGroup } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { RouterModule, Router } from '@angular/router';
import { initTooltips } from 'flowbite';
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { AppComponent } from '../../app.component';
import { UseOfBuilding, IndustrialSideMarginByPlotArea, SideMarginByRoadWidth, RoadMarginByRoadWidth, CongestedRoadMargin } from '../../core/models/sideMarginTable';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/services/auth.service';
import { SeoService } from '../../core/services/seo.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { nagpurUseValues, planningAuthorityMultiplier, useValues } from '../../core/models/parkingTable';

@Component({
  selector: 'app-parking',
  standalone: true,
  imports: [CommonModule, FormsModule, NgOtpInputModule, RouterModule, GoogleSigninButtonModule],
  templateUrl: './parking.component.html',
  styleUrl: './parking.component.scss',
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})
export class ParkingComponent {
  @Input() service = "";
  @Input() projectId = "";
  @Input() calId = "";
  @Input() isModal = false;
  @Input() edit = false;
  @Input() vendorId = "";
  @Input() directSendProposal = false;

  error = false;
  errorMessage: String = "";
  errorCancelAble = false;

  useOfBuildings = UseOfBuilding;

  @Output() closeModal = new EventEmitter<string>();

  userLoggedIn = false;
  serviceDetails: any = [];
  questions: any = [];
  groupedServices: any = [];
  searchGroupedServices: any = [];
  form!: FormGroup;
  totalQuestionCount = 0;
  stepForm = 0;
  currentStep = 1;

  name = "";
  email = "";
  mobile: any;

  orderId: any = "";
  isLoading = false;
  otp = "";
  otpValue = "";
  isOTP = false;
  seconds = 59;
  isResend = false;
  isProject = false;
  Projects = false;

  isNext = false;

  isResult = false;
  selectedItem: any = [];

  projects: any = [];
  projectName: any = "";
  editedName = "";
  NewProject = "";
  newProjectStatus = false;
  currentProjectId: any;
  newProjectName = "";

  //side margin
  locality: any = "Non-Conjested Area";
  specialZone: any = '';
  planningAuthority: any = "Pune Municipal Corporation";
  uses: any = [{use:"", subType:"", unitText:"", quantity: 0, quantityUnit: 'Sq. M.'}];
  finalUses: any = [];

  //calculation fields
  totalCar: any = "";
  totalVisitorCar: any = "";
  grandTotalCar: any = "";
  metroCar: any = "";
  totalScooter: any = "";
  totalVisitorScooter: any = "";
  grandTotalScooter: any = "";
  metroScooter: any = "";

  isSendOTP = false;

  printLoader = false;
  baseURL = environment.baseURL;

  isGoogleAuth: any = false;
  isGoogleAuthRegister: any = false;
  isGoogleAuthUserData: any = [];
  isGoogleAuthSendOTP = false;
  isOTPModal = false;
  isSendOTPModal = false;
  isLoadingModal = false;
  isResendModal = false;
  googleUser: any = {};

  errorModal = false;
  errorMessageModal: String = "";
  errorCancelAbleModal = false;
  constructor(public api: ApiService, public auth: AuthService, public router: Router, public appC: AppComponent, private titleService: Title, private seoService: SeoService, private metaService: Meta,
    @Inject(DOCUMENT) public document: Document, public toastr: ToastrService, @Inject(PLATFORM_ID) private platformId: Object, public authService: SocialAuthService,) {
    this.titleService.setTitle(this.seoService.getTitle("parking"));
    this.metaService.addTags(this.seoService.getSEOMetaData("parking"));

    if (this.auth.currentUserValue) {
      this.userLoggedIn = true;
      this.name = this.auth.currentUserValue.name;
      this.email = this.auth.currentUserValue.email;
      this.mobile = this.auth.currentUserValue.mobile;
    }
    if (isPlatformBrowser(this.platformId)) {
      initTooltips();
    }
  }

  ngOnChanges() {
    this.authService.authState.subscribe(async (user) => {
      this.isGoogleAuth = true;
      if (user) {
        this.googleUser = user;
        let userData = (await this.api.getUser({ email: user.email }, 1, 1, "").toPromise()).data;
        if (userData.length > 0) {
          if (userData[0].mobile) {
            let data: any = { id: userData[0]._id, name: userData[0].name, email: userData[0].email, mobile: userData[0].mobile, walletBalance: userData[0].walletBalance, token: user.idToken, photo: userData[0].photo, userCode: userData[0].userCode, newUser: userData[0].newUser, status: userData[0].status }
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.auth.currentUserSubject.next(data);
            this.appC._fetchData();
            this.userLoggedIn = true;
            if (!this.projectId) {
              this.isProject = true;
              this.api.getProject({ user: userData[0]._id }, 1, 10000, "").subscribe((data: any) => {
                this.projects = data.data;
                this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
                this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
              })
            } else {
              this.calculate();
            }
          } else {
            this.isGoogleAuthRegister = true;
            this.isGoogleAuthUserData = userData[0];
          }
        } else {
        }
      }
    });
    if (this.calId) {
      this.api.getParking({ _id: this.calId }, 1, 20, "").subscribe(data => {
        if (data.data) {
          this.specialZone = data.data[0].specialZone;
          this.locality = data.data[0].locality;
          this.planningAuthority = data.data[0].planningAuthority;
          this.uses = data.data[0].uses;
          this.projectId = data.data[0].project?._id;
          this.projectName = data.data[0].project?.title;
        }
      })
    }
  }

  addUse(){
    this.uses.push({use:"", subType: "", unitText: "", quantity: 0, quantityUnit: 'Sq. M.'})
  }

  deleteUse(i:any){
    this.uses.splice(i,1);
  }

  getUnitText(event:any, item:any){
    if(this.specialZone == 'TOD Zone - Nagpur'){
      item.unitText = nagpurUseValues.find((result:any) => {return result.use == item.use && result.subType == event.target.value})?.unitText;
    }else{
      item.unitText = useValues.find((result:any) => {return result.use == item.use && result.subType == event.target.value})?.unitText;
    } 
  }

  changeUse(item:any){
    item.subType = "";
    item.unitText = "";
    item.quantity = 0;
    item.quantityUnit = "Sq. M.";
  }

  mobileCheckModal(event: any) {
    this.clearError();
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isGoogleAuthSendOTP = true;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showErrorModal("You are a Foot2Feet Partner! Please use mobile application or registered as a new user!", true);
          }
          if (us.data[0].role == "user" && us.data[0]._id != this.isGoogleAuthUserData._id && this.isGoogleAuthRegister) {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
          if (us.data[0].role == "user") {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
        }
      });
    } else {
      this.isGoogleAuthSendOTP = false;
    }

  }

  sendOTPModal() {
    const data = JSON.stringify({
      "mobile": this.mobile,
      "role": "user",
      "isPlatform": "website",
      "isGoogleAuth": true
    });
    this.api.sendOTP(data).subscribe(res => {
      if (res.status) {
        this.orderId = res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }

  resendOTPModal() {
    const data = JSON.stringify({
      "orderId": this.orderId
    });
    this.api.resendOTP(data).subscribe(res => {
      if (res.status) {
        // this.orderId =res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }


  none() { }

  showErrorModal(message: String, cancelAble = false) {
    this.errorModal = true;
    this.errorMessageModal = message;
    this.errorCancelAbleModal = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 10000);
    }

  }

  clearErrorModal() {
    this.errorModal = false;
    this.errorMessageModal = "";
    this.errorCancelAbleModal = false;
  }

  onMobileInputModal(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  nextStep() {
    this.isNext = true;
    if (this.currentStep == 1 && this.planningAuthority != '' && this.locality != '' && this.specialZone != '') {
      this.currentStep++;
      this.isNext = false;
    }

  }

  AddProject() {
    this.Projects = true;
  }

  createdNewProject() {
    this.newProjectStatus = true;
    if (!this.newProjectName) {
      this.toastr.error("Enter a project name");
      this.newProjectStatus = false;
      return;
    }
    const data = JSON.stringify({
      title: this.newProjectName,
      user: this.auth.currentUserValue.id,
    })
    this.api.createProject(data).subscribe((cdata: any) => {
      if (cdata.status) {
        this.Projects = false;
        this.projects.push({
          "_id": cdata.data._id,
          "title": this.newProjectName,
          "isDefault": false,
          "user": this.auth.currentUserValue,
          "createdAt": new Date()
        });
        this.newProjectName = "";
        this.newProjectStatus = false;
      } else {
        this.toastr.error(cdata.message);
        this.newProjectStatus = false;
      }
    }, error => {
      this.toastr.error(error.message);
      this.newProjectStatus = false;
    })
  }

  editProject(item: any, i: any) {
    item.editProjects = true;
    this.currentProjectId = item._id;
    setTimeout(() => {
      this.document.getElementById('input' + i)?.focus();
    }, 200);


  }

  editProjectName(item: any) {
    const data = JSON.stringify({
      title: item.title
    })
    this.api.updateProject(data, this.currentProjectId).subscribe((cdata: any) => {
      if (cdata.status) {
        item.editProjects = false;
      } else {
        this.toastr.error(cdata.message);
        this.newProjectStatus = false;
      }
    }, error => {
      this.toastr.error(error.message);
      this.newProjectStatus = false;
    })
  }

  getProjectName(event: any, def: any) {
    if (def) {
      this.projectName = "My project";
    } else {
      this.projectName = this.projects[Number(event.target.id)].title;
    }
  }

  nextSlide() {
    if (this.userLoggedIn) {
      this.isProject = true;
      this.api.getProject({ user: this.auth.currentUserValue.id }, 1, 10000, "").subscribe((data: any) => {
        this.projects = data.data;
        this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
        this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
      })
    } else {
      // if(this.otp == this.otpValue){
      if (this.isGoogleAuth) {

        this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {

          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();
          this.userLoggedIn = true;
          this.isProject = true;
          this.api.getProject({ user: res.data.id }, 1, 10000, "").subscribe((data: any) => {
            this.projects = data.data;
            this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
            this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
          })
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true);
        });
      } else {
        this.api.authenticateUser({ name: this.name, mobile: this.mobile, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {

          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();
          this.userLoggedIn = true;
          this.isProject = true;
          this.api.getProject({ user: res.data.id }, 1, 10000, "").subscribe((data: any) => {
            this.projects = data.data;
            this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
            this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
          })
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true);
        });
      }
      // }else{
      //   this.isLoading = false;
      //   this.showError("Incorrect OTP",true);
      // }
    }
  }

  backStep() {
    this.currentStep--;
  }

  sendOTP() {
    if (this.isGoogleAuth) {
      this.isNext = true;
      const data = JSON.stringify({
        "mobile": this.mobile,
        "role": "user",
        "isPlatform": "website",
        "isGoogleAuth": true
      });
      this.isLoadingModal = true;
      if (this.isSendOTP && this.name != '' && this.email != '') {
        this.isNext = false;
      }
      this.api.sendOTP(data).subscribe(res => {
        if (res.status) {
          this.orderId = res.data.orderId;
          // this.otp = res.data.otp;
          this.isOTPModal = true;
          this.isLoadingModal = false;
          this.countdown();
        } else {
          this.isLoadingModal = false;
          this.showError(res.message, true);
        }
      }, error => {
        this.isLoadingModal = false;

        this.showError(error.error.error.message, true);
      });
    } else {
      this.isNext = true;
      const data = JSON.stringify({
        "mobile": this.mobile,
        "role": "user",
        "name": this.name,
        "email": this.email,
        "isPlatform": "website"
      });
      this.isLoading = true;
      if (this.isSendOTP && this.name != '' && this.email != '') {
        this.isNext = false;
      }
      this.api.sendOTP(data).subscribe(res => {
        if (res.status) {
          this.orderId = res.data.orderId;
          // this.otp = res.data.otp;
          this.isOTP = true;
          this.isLoading = false;
          this.countdown();
        } else {
          this.isLoading = false;
          this.showError(res.message, true);
        }
      }, error => {
        this.isLoading = false;

        this.showError(error.error.error.message, true);
      });
    }
  }

  navigate() {
    if (this.isModal) {
      this.closeModal.emit('close');
    } else {
      this.router.navigate(['/project', this.projectId])
    }
  }

  mobileCheck(event: any) {
    this.isLoading = true;
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isSendOTP = true;
          this.isLoading = false;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showError("This Mobile No is assosiated with Partner Profile. Please try another mobile no to access website.");
            this.isSendOTP = false;
            this.isLoading = false;
          } else {
            this.isSendOTP = false;
            this.isLoading = false;
          }
        }
      });
    } else {
      this.isSendOTP = false;
      this.isLoading = false;
    }

  }

  showError(message: String, cancelAble = false) {
    this.error = true;
    this.errorMessage = message;
    this.errorCancelAble = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 4000);
    }

  }

  otpEnterd(event: any) {
    if (event.length == 6) {
      this.otpValue = event;
    }
  }

  onMobileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  countdown() {
    this.seconds = 59;
    let interval = setInterval(() => {
      this.seconds--;

      if (this.seconds <= 0) {
        clearInterval(interval);
        this.isResend = true;
      }
    }, 1000);
  }

  onSubmit() {
    if (this.userLoggedIn) {
      if (this.currentStep == 2) {
        this.isNext = true;

        let allConditionsMet = true;

        for (let i = 0; i < this.uses.length; i++) {
          if (this.uses[i].use == '') {
            allConditionsMet = false;
          }else if(this.uses[i].subType == ''){
            allConditionsMet = false;
          }else if (this.uses[i].quantity == '') {
            allConditionsMet = false;
          }
          
          if (i == (this.uses.length - 1)) {
            if (allConditionsMet) {
              this.isNext = false;
              this.currentStep++;
            }
          }
        }
      } else {
        this.calculate();
      }
    } else {
      if (this.currentStep == 2) {
        this.isNext = true;

        let allConditionsMet = true;

        for (let i = 0; i < this.uses.length; i++) {
          if (this.uses[i].use == '') {
            allConditionsMet = false;
          }else if(this.uses[i].subType == ''){
            allConditionsMet = false;
          }else if (this.uses[i].quantity == '') {
            allConditionsMet = false;
          }
          
          if (i == (this.uses.length - 1)) {
            if (allConditionsMet) {
              this.isNext = false;
              this.currentStep++;
            }
          }
        }
      } else {
        // if(this.otp == this.otpValue){
        if (this.isGoogleAuth) {
          this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {

            localStorage.setItem('currentUser', JSON.stringify(res.data));
            this.auth.currentUserSubject.next(res.data);
            this.appC._fetchData();
            this.calculate();
          }, error => {
            this.isLoading = false;
            this.showError(error.error.error.message, true);
          });
        } else {
          this.api.authenticateUser({ name: this.name, mobile: this.mobile, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {

            localStorage.setItem('currentUser', JSON.stringify(res.data));
            this.auth.currentUserSubject.next(res.data);
            this.appC._fetchData();
            this.calculate();
          }, error => {
            this.isLoading = false;
            this.showError(error.error.error.message, true);
          });
        }
        // }else{
        //   this.isLoading = false;
        //   this.showError("Incorrect OTP",true);
        // }
      }
    }
  }

  clearError() {
    this.error = false;
    this.errorMessage = "";
    this.errorCancelAble = false;
  }

  async parkingCalculateData() {
    let promises = this.uses.map((res:any) => {
      let quantity: any;
      (res.quantityUnit == 'Sq. M.') ? quantity = res.quantity : quantity = (res.quantity / 10.764).toFixed(2);
      let carMultipler:any = planningAuthorityMultiplier.find((result:any) => { return result.name == this.planningAuthority})?.multiplier;
      let scooterMultiplier:any = (res.use == 'Residential Units')? 1 :(planningAuthorityMultiplier.find((result:any) => { return result.name == this.planningAuthority})?.multiplier)
      let divisible: any;
      let carFactor: any;
      let scooterFactor: any;
      let car: any;
      let visitorCar: any;
      let scooter: any;
      let visitorScooter: any;
      let visitor: any;

      if(this.specialZone == 'TOD Zone - Nagpur'){
        divisible = nagpurUseValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.divisible;
        carFactor = nagpurUseValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.carFactor;
        scooterFactor = nagpurUseValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.scooterFactor;
        car = parseInt(((quantity * carMultipler * carFactor) / divisible).toFixed(0));
        visitorCar = car;
        scooter = parseInt(((quantity * scooterMultiplier * scooterFactor) / divisible).toFixed(0));
        visitorScooter = scooter;
        return { ...res, totalQuantity: quantity, carMultipler: carMultipler, scooterMultiplier: scooterMultiplier, 
          divisible: divisible, carFactor: carFactor, scooterFactor: scooterFactor, visitor: visitor, car: car, visitorCar: visitorCar,
          scooter: scooter, visitorScooter: visitorScooter}
      }else{
        divisible = useValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.divisible;
        carFactor = (this.locality == 'Conjested Area (Gaothan)')?(useValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.conjestedCarFactor):(useValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.nonConjestedCarFactor);
        scooterFactor = (this.locality == 'Conjested Area (Gaothan)')?(useValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.conjestedScooterFactor):(useValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.nonConjestedScooterFactor);
        visitor = useValues.find((result:any) => {return result.use == res.use && result.subType == res.subType})?.visitor;
        car = parseInt(((quantity * carMultipler * carFactor) / divisible).toFixed(0));
        visitorCar = parseInt(((visitor / 100) * car).toFixed(0));
        scooter = parseInt(((quantity * scooterMultiplier * scooterFactor) / divisible).toFixed(0));
        visitorScooter = parseInt(((visitor / 100) * scooter).toFixed(0));
        return { ...res, totalQuantity: quantity, carMultipler: carMultipler, scooterMultiplier: scooterMultiplier, 
        divisible: divisible, carFactor: carFactor, scooterFactor: scooterFactor, visitor: visitor, car: car, visitorCar: visitorCar,
        scooter: scooter, visitorScooter: visitorScooter}
      }
    })

    this.finalUses = await Promise.all(promises); 
    this.totalCar = this.finalUses.reduce((acc:any, obj:any) => { return (acc * 1) + (obj.car * 1); }, 0);
    this.totalVisitorCar = this.finalUses.reduce((acc:any, obj:any) => { return (acc * 1) + (obj.visitorCar * 1); }, 0);
    this.grandTotalCar = (this.totalCar * 1) + (this.totalVisitorCar * 1);
    this.metroCar = (this.grandTotalCar * 0.5).toFixed(0)

    this.totalScooter = this.finalUses.reduce((acc:any, obj:any) => { return (acc * 1) + (obj.scooter * 1); }, 0);
    this.totalVisitorScooter = this.finalUses.reduce((acc:any, obj:any) => { return (acc * 1) + (obj.visitorScooter * 1); }, 0);
    this.grandTotalScooter = (this.totalScooter * 1) + (this.totalVisitorScooter * 1);
    this.metroScooter = (this.grandTotalScooter * 0.5).toFixed(0);
  }

  openCalculatorModal() {
    this.router.navigate(['/project', this.projectId], {
      queryParams: {
        id: this.selectedItem._id,
        calculator: 'Parking'
      }
    })
  }

  calculate() {
    this.isLoading = true;
    if (!this.projectId) {
      this.isNext = true;
      this.isLoading = false;
      return;
    }
    Swal.fire({
      title: "Calculating...",
      icon: 'success',
      timer: 1500,
      allowOutsideClick: false,
      showConfirmButton: false
    }).then(async (result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        await this.parkingCalculateData();
        if (!this.edit) {
          // if(this.projects.length == 0){
          //   const pdata = JSON.stringify({
          //     title: "My Project",
          //     isDefault: true,
          //     user: this.auth.currentUserValue.id
          //   })
          //   this.api.createProject(pdata).subscribe((presData:any) => { 
          //     if(presData.status){
          //       this.projectId = presData.data._id;
          //       const data = JSON.stringify({
          //         plotArea:this.plotArea,
          //         plotAreaUnit:this.plotAreaUnit,
          //         locality:this.locality,
          //         planningAuthority:this.planningAuthority,
          //         useOfBuilding:this.useOfBuilding,
          //         heightFromGround:this.heightFromGround,
          //         heightFromGroundUnit:this.heightFromGroundUnit,
          //         heightExclusiveParking:this.heightExclusiveParking,
          //         heightExclusiveParkingUnit:this.heightExclusiveParkingUnit,
          //         heightExcludingParking:this.heightExcludingParking,
          //         heightExcludingParkingUnit:this.heightExcludingParkingUnit,
          //         maxAreaSingleFloor:this.maxAreaSingleFloor,
          //         maxAreaSingleFloorUnit:this.maxAreaSingleFloorUnit,
          //         side1:this.side1,
          //         side2:this.side2,
          //         side3:this.side3,
          //         side4:this.side4,
          //         user:this.auth.currentUserValue.id,
          //         project:this.projectId,
          //         typeOfBuilding:this.typeOfBuilding,
          //         maximumRoadWidth:this.maximumRoadWidth,
          //       })
          //       this.api.createSideMargin(data).subscribe(res=>{
          //         this.isLoading = false;
          //         this.closeModal.emit('close');
          //         this.isResult = true;
          //         this.selectedItem = res.data;
          //         // this.router.navigate(['/project',res.data.project]);
          //       })
          //     }
          //   });
          // }else{
          const data = JSON.stringify({
            planningAuthority: this.planningAuthority,
            locality: this.locality,
            specialZone: this.specialZone,
            uses: this.finalUses,
            totalCar: this.totalCar,
            totalVisitorCar: this.totalVisitorCar,
            grandTotalCar: this.grandTotalCar,
            metroCar: this.metroCar,
            totalScooter: this.totalScooter,
            totalVisitorScooter: this.totalVisitorScooter,
            grandTotalScooter: this.grandTotalScooter,
            metroScooter: this.metroScooter,
            user: this.auth.currentUserValue.id,
            project: this.projectId
          })
          this.api.createParking(data).subscribe(res => {
            this.isLoading = false;
            this.closeModal.emit('close');
            this.isResult = true;
            this.selectedItem = res.data;
            // this.router.navigate(['/project',res.data.project]);
          })
          // }
        } else {
          const data = JSON.stringify({
            planningAuthority: this.planningAuthority,
            locality: this.locality,
            specialZone: this.specialZone,
            uses: this.finalUses,
            totalCar: this.totalCar,
            totalVisitorCar: this.totalVisitorCar,
            grandTotalCar: this.grandTotalCar,
            metroCar: this.metroCar,
            totalScooter: this.totalScooter,
            totalVisitorScooter: this.totalVisitorScooter,
            grandTotalScooter: this.grandTotalScooter,
            metroScooter: this.metroScooter
          })
          this.api.updateParking(data, this.calId).subscribe(res => {
            this.isLoading = false;
            // this.router.navigate(['/project',this.projectId]);
            this.isResult = true;
            this.selectedItem = res.data;
          })
        }
      }
    });

  }

  parkingDownload() {
    this.printLoader = true;
    this.api.downloadParking(this.selectedItem._id).subscribe(data => {
      const link = document.createElement('a');
      link.href = this.baseURL + '/file/retrieve-pdf/' + data.data;
      link.target = '_blank';
      link.download = 'Parking Calculator.pdf'; // Set the desired filename
      link.click();
      this.printLoader = false;
    })
  }
}
