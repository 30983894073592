import { Component, EventEmitter, Inject, Input, OnChanges, Output, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { animate, style, transition, trigger } from "@angular/animations";
import { Router, RouterModule } from '@angular/router';
import { Fsi, UseOfBuilding } from '../../core/models/fsiTable';
import Swal from 'sweetalert2';
import { Meta, Title } from '@angular/platform-browser';
import { AppComponent } from '../../app.component';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/services/auth.service';
import { SeoService } from '../../core/services/seo.service';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-fsi-calculator',
  standalone: true,
  imports: [CommonModule, FsiCalculatorComponent, FormsModule, NgOtpInputModule, RouterModule, GoogleSigninButtonModule],
  templateUrl: './fsi-calculator.component.html',
  styleUrls: ['./fsi-calculator.component.scss']
})
export class FsiCalculatorComponent implements OnChanges {
  @Input() service = "";
  @Input() projectId = "";
  @Input() calId = "";
  @Input() isModal = false;
  @Input() edit = false;
  @Input() vendorId = "";
  @Input() directSendProposal = false;

  error = false;
  errorMessage: String = "";
  errorCancelAble = false;

  @Output() closeModal = new EventEmitter<string>();

  userLoggedIn = false;
  serviceDetails: any = [];
  questions: any = [];
  groupedServices: any = [];
  searchGroupedServices: any = [];
  form!: FormGroup;
  totalQuestionCount = 0;
  stepForm = 0;
  currentStep = 1;

  name = "";
  email = "";
  mobile: any;
  newProjectName = "";

  orderId: any = "";
  isLoading = false;
  otp = "";
  otpValue = "";
  isOTP = false;
  seconds = 59;
  isResend = false;
  isProject = false;

  isNext = false;

  isResult = false;
  selectedItem: any = [];
  Projects = false;
  projects: any = [];
  projectName: any = "";

  //fsi
  rulesApplicability: any = "Municipal Corporation - Non Conjested";
  plotArea: any = 0;
  plotAreaUnit: any = "Sq. M.";
  deductionArea: any = 0;
  deductionAreaUnit: any = "Sq. M.";
  widthRoad: any = 0;
  widthRoadUnit: any = "Meter";
  gunthewariCertificate: any = "No";
  sanctionedLayout: any = "No";
  openSpace: any = "No";
  myLandCost: any = "No";
  redevelopementProposal: any = "No";
  useBuilding: any = "Any Residential Building";

  //calculation fields
  fullFsiFactor: any = "";
  basicFsiFactor: any = "";
  premiumFsiFactor: any = "";
  tdrFactor: any = "";

  accessRoadWidthInMeter: any = 0;
  areaOfPlotSqm: any = 0;
  areaOfPlotSqft: any = 0;
  deductionRoadSqm: any = 0;
  deductionRoadSqft: any = 0;
  balanceAreaPlotSqm: any = 0;
  balanceAreaPlotSqft: any = 0;
  amenitySpaceRequired: any = "";
  requiredAmenitySpaceSqm: any = 0;
  requiredAmenitySpaceSqft: any = 0;
  netAreaPlotSqm: any = 0;
  netAreaPlotSqft: any = 0;
  openSpaceRequired: any = "";
  requiredOpenSpaceSqm: any = 0;
  requiredOpenSpaceSqft: any = 0;
  requiredPlaygroundSqm: any = 0;
  requiredPlaygroundSqft: any = 0;
  requiredOpenSpacePlaygroundSqm: any = 0;
  requiredOpenSpacePlaygroundSqft: any = 0;
  internalRoadAreaSqm: any = 0;
  internalRoadAreaSqft: any = 0;
  basicFsiSqm: any = 0;
  basicFsiSqft: any = 0;
  additionFsiSqm: any = 0;
  additionFsiSqft: any = 0;
  tdrFsiSqm: any = 0;
  tdrFsiSqft: any = 0;
  redevelopementPercent: any = 0;
  redevelopementFsiSqm: any = 0;
  redevelopementFsiSqft: any = 0;
  additionalFsiSqm: any = 0;
  additionalFsiSqft: any = 0;
  totalFsiSqm: any = 0;
  totalFsiSqft: any = 0;
  anciliaryFsiPercent: any = 0;
  anciliaryFsiSqm: any = 0;
  totalFsiIncludeAnciliaryFsiSqm: any = 0;
  totalFsiIncludeAnciliaryFsiSqft: any = 0;

  tdrFsi: any = 0;
  basicFsi: any = 0;
  premiumFsi: any = 0;

  isSendOTP = false;
  selectedProject: any;

  editedName = "";
  NewProject = "";
  newProjectStatus = false;
  currentProjectId: any;

  useOfBuildings: any = [];

  printLoader: any = false;
  baseURL = environment.baseURL

  isGoogleAuth: any = false;
  isGoogleAuthRegister: any = false;
  isGoogleAuthUserData: any = [];
  isGoogleAuthSendOTP = false;
  isOTPModal = false;
  isSendOTPModal = false;
  isLoadingModal = false;
  isResendModal = false;
  googleUser: any = {};

  errorModal = false;
  errorMessageModal: String = "";
  errorCancelAbleModal = false;
  constructor(public api: ApiService, public auth: AuthService, public router: Router, public appC: AppComponent, private titleService: Title, private seoService: SeoService, private metaService: Meta,
    @Inject(DOCUMENT) public document: Document, public toastr: ToastrService, @Inject(PLATFORM_ID) private platformId: Object, public authService: SocialAuthService,) {
    this.titleService.setTitle(this.seoService.getTitle("fsi"));
    this.metaService.addTags(this.seoService.getSEOMetaData("fsi"));
    if (this.auth.currentUserValue) {
      this.userLoggedIn = true;
      this.name = this.auth.currentUserValue.name;
      this.email = this.auth.currentUserValue.email;
      this.mobile = this.auth.currentUserValue.mobile;
    }

    if (isPlatformBrowser(this.platformId)) {
    }
    this.useOfBuildings = UseOfBuilding;

  }

  getProjectName(event: any, def: any) {
    if (def) {
      this.projectName = "My project";
    } else {
      this.projectName = this.projects[Number(event.target.id)].title;
    }
  }

  ngOnChanges() {
    this.authService.authState.subscribe(async (user) => {
      this.isGoogleAuth = true;
      if (user) {
        this.googleUser = user;
        let userData = (await this.api.getUser({ email: user.email }, 1, 1, "").toPromise()).data;
        if (userData.length > 0) {
          if (userData[0].mobile) {
            let data: any = { id: userData[0]._id, name: userData[0].name, email: userData[0].email, mobile: userData[0].mobile, walletBalance: userData[0].walletBalance, token: user.idToken, photo: userData[0].photo, userCode: userData[0].userCode, newUser: userData[0].newUser, status: userData[0].status }
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.auth.currentUserSubject.next(data);
            this.appC._fetchData();
            this.userLoggedIn = true;
            if (!this.projectId) {
              this.isProject = true;
              this.api.getProject({ user: userData[0]._id }, 1, 10000, "").subscribe((data: any) => {
                this.projects = data.data;
                this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
                this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
              })
            } else {
              this.calculate();
            }
          } else {
            this.isGoogleAuthRegister = true;
            this.isGoogleAuthUserData = userData[0];
          }
        } else {
        }
      }
    });
    if (this.calId) {
      this.api.getFsi({ _id: this.calId }, 1, 20, "").subscribe(data => {
        if (data.data) {

          this.rulesApplicability = data.data[0].rulesApplicability;
          this.plotArea = data.data[0].plotArea;
          this.plotAreaUnit = data.data[0].plotAreaUnit;
          this.deductionArea = data.data[0].deductionArea;
          this.deductionAreaUnit = data.data[0].deductionAreaUnit;
          this.widthRoad = data.data[0].widthRoad;
          this.widthRoadUnit = data.data[0].widthRoadUnit;
          this.gunthewariCertificate = data.data[0].gunthewariCertificate;
          this.sanctionedLayout = data.data[0].sanctionedLayout;
          this.openSpace = data.data[0].openSpace;
          this.myLandCost = data.data[0].myLandCost;
          this.redevelopementProposal = data.data[0].redevelopmentProposal;
          this.useBuilding = data.data[0].useBuilding;
          this.projectId = data.data[0].project?._id;
          this.projectName = data.data[0].project?.title;
        }
      })
    }
  }

  mobileCheckModal(event: any) {
    this.clearError();
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isGoogleAuthSendOTP = true;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showErrorModal("You are a Foot2Feet Partner! Please use mobile application or registered as a new user!", true);
          }
          if (us.data[0].role == "user" && us.data[0]._id != this.isGoogleAuthUserData._id && this.isGoogleAuthRegister) {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
          if (us.data[0].role == "user") {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
        }
      });
    } else {
      this.isGoogleAuthSendOTP = false;
    }

  }

  sendOTPModal() {
    const data = JSON.stringify({
      "mobile": this.mobile,
      "role": "user",
      "isPlatform": "website",
      "isGoogleAuth": true
    });
    this.api.sendOTP(data).subscribe(res => {
      if (res.status) {
        this.orderId = res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }

  resendOTPModal() {
    const data = JSON.stringify({
      "orderId": this.orderId
    });
    this.api.resendOTP(data).subscribe(res => {
      if (res.status) {
        // this.orderId =res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }


  none() { }

  showErrorModal(message: String, cancelAble = false) {
    this.errorModal = true;
    this.errorMessageModal = message;
    this.errorCancelAbleModal = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 10000);
    }

  }

  clearErrorModal() {
    this.errorModal = false;
    this.errorMessageModal = "";
    this.errorCancelAbleModal = false;
  }

  onMobileInputModal(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  AddProject() {
    this.Projects = true;
  }

  createdNewProject() {
    this.newProjectStatus = true;
    if (!this.newProjectName) {
      this.toastr.error("Enter a project name");
      this.newProjectStatus = false;
      return;
    }
    const data = JSON.stringify({
      title: this.newProjectName,
      user: this.auth.currentUserValue.id,
    })
    this.api.createProject(data).subscribe((cdata: any) => {
      if (cdata.status) {
        this.Projects = false;
        this.projects.push({
          "_id": cdata.data._id,
          "title": this.newProjectName,
          "isDefault": false,
          "user": this.auth.currentUserValue,
          "createdAt": new Date()
        });
        this.newProjectName = "";
        this.newProjectStatus = false;
      } else {
        this.toastr.error(cdata.message);
        this.newProjectStatus = false;
      }
    }, error => {
      this.toastr.error(error.message);
      this.newProjectStatus = false;
    })
  }

  openCalculatorModal() {
    this.router.navigate(['/project', this.projectId], {
      queryParams: {
        id: this.selectedItem._id,
        calculator: 'FSI'
      }
    })
  }
  nextStep() {
    this.isNext = true;
    if (this.currentStep == 1 && this.plotArea != '' && this.widthRoad != '') {
      this.currentStep++;
      this.isNext = false;
    }

  }

  backStep() {
    this.currentStep--;
  }

  sendOTP() {
    if (this.isGoogleAuth) {
      this.isNext = true;
      const data = JSON.stringify({
        "mobile": this.mobile,
        "role": "user",
        "isPlatform": "website",
        "isGoogleAuth": true
      });
      this.isLoadingModal = true;
      if (this.isSendOTP && this.name != '' && this.email != '') {
        this.isNext = false;
      }
      this.api.sendOTP(data).subscribe(res => {
        if (res.status) {
          this.orderId = res.data.orderId;
          // this.otp = res.data.otp;
          this.isOTPModal = true;
          this.isLoadingModal = false;
          this.countdown();
        } else {
          this.isLoadingModal = false;
          this.showError(res.message, true);
        }
      }, error => {
        this.isLoadingModal = false;

        this.showError(error.error.error.message, true);
      });
    } else {
      this.isNext = true;
      const data = JSON.stringify({
        "mobile": this.mobile,
        "role": "user",
        "name": this.name,
        "email": this.email,
        "isPlatform": "website"
      });
      this.isLoading = true;
      if (this.isSendOTP && this.name != '' && this.email != '') {
        this.isNext = false;
      }
      this.api.sendOTP(data).subscribe(res => {
        if (res.status) {
          this.orderId = res.data.orderId;
          // this.otp = res.data.otp;
          this.isOTP = true;
          this.isLoading = false;
          this.countdown();
        } else {
          this.isLoading = false;
          this.showError(res.message, true);
        }
      }, error => {
        this.isLoading = false;

        this.showError(error.error.error.message, true);
      });
    }
  }

  mobileCheck(event: any) {
    this.isLoading = true;
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isSendOTP = true;
          this.isLoading = false;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showError("This Mobile No is assosiated with Partner Profile. Please try another mobile no to access website.");
            this.isSendOTP = false;
            this.isLoading = false;
          } else {
            this.isSendOTP = false;
            this.isLoading = false;
          }
        }
      });
    } else {
      this.isSendOTP = false;
      this.isLoading = false;
    }

  }

  editProject(item: any, i: any) {
    item.editProjects = true;
    this.currentProjectId = item._id;
    setTimeout(() => {
      this.document.getElementById('input' + i)?.focus();
    }, 200);


  }

  editProjectName(item: any) {
    const data = JSON.stringify({
      title: item.title
    })
    this.api.updateProject(data, this.currentProjectId).subscribe((cdata: any) => {
      if (cdata.status) {
        item.editProjects = false;
      } else {
        this.toastr.error(cdata.message);
        this.newProjectStatus = false;
      }
    }, error => {
      this.toastr.error(error.message);
      this.newProjectStatus = false;
    })
  }

  showError(message: String, cancelAble = false) {
    this.error = true;
    this.errorMessage = message;
    this.errorCancelAble = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 4000);
    }


  }

  otpEnterd(event: any) {
    if (event.length == 6) {
      this.otpValue = event;
    }
  }

  onMobileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  countdown() {
    this.seconds = 59;
    let interval = setInterval(() => {
      this.seconds--;

      if (this.seconds <= 0) {
        clearInterval(interval);
        this.isResend = true;
      }
    }, 1000);
  }

  onSubmit() {
    if (this.userLoggedIn) {
      if (this.currentStep == 2) {
        this.currentStep++;
      } else {
        this.calculate();
      }
    } else {
      if (this.currentStep == 2) {
        this.currentStep++;
      } else {
        if (this.isGoogleAuth) {
          this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {

            localStorage.setItem('currentUser', JSON.stringify(res.data));
            this.auth.currentUserSubject.next(res.data);
            this.appC._fetchData();
            this.calculate();
          }, error => {
            this.isLoading = false;
            this.showError(error.error.error.message, true);
          });
        } else {
          this.api.authenticateUser({ name: this.name, mobile: this.mobile, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {

            localStorage.setItem('currentUser', JSON.stringify(res.data));
            this.auth.currentUserSubject.next(res.data);
            this.appC._fetchData();
            this.calculate();
          }, error => {
            this.isLoading = false;
            this.showError(error.error.error.message, true);
          });
        }
        // }else{
        //   this.isLoading = false;
        //   this.showError("Incorrect OTP",true);
        // }
      }
    }
  }

  clearError() {
    this.error = false;
    this.errorMessage = "";
    this.errorCancelAble = false;
  }

  fsiCalculateData() {
    (this.widthRoadUnit == 'Meter') ? this.accessRoadWidthInMeter = this.widthRoad : this.accessRoadWidthInMeter = (this.widthRoad / 3.28).toFixed(2);

    (this.plotAreaUnit == 'Sq. M.') ? this.areaOfPlotSqm = this.plotArea : this.areaOfPlotSqm = (this.plotArea / 10.764).toFixed(2);
    this.areaOfPlotSqft = (this.areaOfPlotSqm * 10.764).toFixed(0);

    (this.deductionAreaUnit == 'Sq. M.') ? this.deductionRoadSqm = this.deductionArea : this.deductionRoadSqm = (this.deductionArea / 10.764).toFixed(2);
    this.deductionRoadSqft = (this.deductionRoadSqm * 10.764).toFixed(0);

    this.balanceAreaPlotSqm = (this.areaOfPlotSqm - this.deductionRoadSqm).toFixed(2);
    this.balanceAreaPlotSqft = (this.balanceAreaPlotSqm * 10.764).toFixed(0);

    (this.sanctionedLayout == 'Yes' || this.balanceAreaPlotSqm < 20000) ? this.amenitySpaceRequired = 'NO' : this.amenitySpaceRequired = 'YES';
    (this.amenitySpaceRequired == 'YES') ? this.requiredAmenitySpaceSqm = (this.balanceAreaPlotSqm * (5 / 100)).toFixed(2) : this.requiredAmenitySpaceSqm = 0;
    this.requiredAmenitySpaceSqft = (this.requiredAmenitySpaceSqm * 10.764).toFixed(0);

    this.netAreaPlotSqm = (this.balanceAreaPlotSqm - this.requiredAmenitySpaceSqm).toFixed(2);
    this.netAreaPlotSqft = (this.netAreaPlotSqm * 10.764).toFixed(0);

    (this.sanctionedLayout == 'Yes') ? this.openSpaceRequired = 'NO' : ((this.openSpace == 'Yes') ? this.openSpaceRequired = 'YES' : ((this.balanceAreaPlotSqm >= 4000) ? this.openSpaceRequired = 'YES' : 'NO'));

    let useOfBuildingData = this.useOfBuildings.filter((res: any) => res.type == this.useBuilding);
    this.anciliaryFsiPercent = useOfBuildingData[0].fsipercent;
    if (useOfBuildingData[0].openspace) {
      this.requiredOpenSpaceSqm = Math.max(200, (this.balanceAreaPlotSqm * 10 / 100));
      this.requiredOpenSpaceSqft = (this.requiredOpenSpaceSqm * 10.764).toFixed(0);
      this.requiredOpenSpacePlaygroundSqm = this.requiredOpenSpaceSqm;
      this.requiredOpenSpacePlaygroundSqft = this.requiredOpenSpaceSqft;
    } else {
      (this.balanceAreaPlotSqm > 40000) ? this.requiredPlaygroundSqm = (20 / 100 * (this.balanceAreaPlotSqm - 40000) + 13000).toFixed(2) :
        (this.balanceAreaPlotSqm > 30000) ? this.requiredPlaygroundSqm = (25 / 100 * (this.balanceAreaPlotSqm - 30000) + 10500).toFixed(2) :
          (this.balanceAreaPlotSqm > 20000) ? this.requiredPlaygroundSqm = (30 / 100 * (this.balanceAreaPlotSqm - 20000) + 7500).toFixed(2) :
            (this.balanceAreaPlotSqm > 10000) ? this.requiredPlaygroundSqm = (35 / 100 * (this.balanceAreaPlotSqm - 10000) + 4000).toFixed(2) :
              this.requiredPlaygroundSqm = (40 / 100 * this.balanceAreaPlotSqm).toFixed(2)
      this.requiredPlaygroundSqft = (this.requiredPlaygroundSqm * 10.764).toFixed(0);
      this.requiredOpenSpacePlaygroundSqm = this.requiredPlaygroundSqm;
      this.requiredOpenSpacePlaygroundSqft = this.requiredPlaygroundSqft;
    }

    (this.openSpaceRequired == 'YES' || this.sanctionedLayout == 'Yes' || this.openSpace == 'Yes' || this.myLandCost == 'Yes') ? this.fullFsiFactor = 'Full FSI' : ((this.gunthewariCertificate == 'Yes') ? this.fullFsiFactor = 'Gunthewari' : this.fullFsiFactor = 'Other');
    (this.fullFsiFactor == 'Full FSI') ? this.basicFsiFactor = 100 : ((this.fullFsiFactor == 'Gunthewari') ? this.basicFsiFactor = 100 : this.basicFsiFactor = 75);
    (this.fullFsiFactor == 'Full FSI') ? this.premiumFsiFactor = 100 : ((this.fullFsiFactor == 'Gunthewari') ? this.premiumFsiFactor = 100 : this.premiumFsiFactor = 75);
    (this.fullFsiFactor == 'Full FSI') ? this.tdrFactor = 100 : ((this.fullFsiFactor == 'Gunthewari') ? this.tdrFactor = 50 : this.tdrFactor = 50);

    let fsi = Fsi.filter((res: any) => res.title == this.rulesApplicability);
    let fsiCompare = fsi[0]?.table.filter((res: any) => ((this.accessRoadWidthInMeter >= res.roadWidthStart) && (this.accessRoadWidthInMeter <= res.roadWidthEnd)));

    this.basicFsi = fsiCompare[0].basicFsi;
    this.basicFsiSqm = (this.netAreaPlotSqm * this.basicFsi * (this.basicFsiFactor / 100)).toFixed(2);
    this.basicFsiSqft = (this.basicFsiSqm * 10.764).toFixed(0);

    this.premiumFsi = fsiCompare[0].premiumFsi;
    this.additionFsiSqm = (this.areaOfPlotSqm * this.premiumFsi * (this.premiumFsiFactor / 100)).toFixed(2);
    this.additionFsiSqft = (this.additionFsiSqm * 10.764).toFixed(0);

    this.tdrFsi = fsiCompare[0].tdr;
    this.tdrFsiSqm = (this.areaOfPlotSqm * this.tdrFsi * (this.tdrFactor / 100)).toFixed(2);
    this.tdrFsiSqft = (this.tdrFsiSqm * 10.764).toFixed(0);

    (this.redevelopementProposal == 'Yes') ? this.redevelopementPercent = 30 : this.redevelopementPercent = 0;
    this.redevelopementFsiSqm = (this.areaOfPlotSqm * (this.redevelopementPercent / 100)).toFixed(2);
    this.redevelopementFsiSqft = (this.redevelopementFsiSqm * 10.764).toFixed(0);
    this.additionalFsiSqm = this.redevelopementFsiSqm;
    this.additionalFsiSqft = this.redevelopementFsiSqft;

    this.totalFsiSqm = ((this.basicFsiSqm * 1) + Math.max((Number(this.additionFsiSqm) + Number(this.tdrFsiSqm)), this.additionalFsiSqm)).toFixed(2);
    this.totalFsiSqft = (this.totalFsiSqm * 10.764).toFixed(0);

    this.anciliaryFsiSqm = (this.totalFsiSqm * (this.anciliaryFsiPercent / 100)).toFixed(2);

    this.totalFsiIncludeAnciliaryFsiSqm = ((this.totalFsiSqm * 1) + (this.anciliaryFsiSqm * 1)).toFixed(2);
    this.totalFsiIncludeAnciliaryFsiSqft = (this.totalFsiIncludeAnciliaryFsiSqm * 10.764).toFixed(0);
  }

  nextSlide() {
    if (this.userLoggedIn) {
      this.isProject = true;
      this.api.getProject({ user: this.auth.currentUserValue.id }, 1, 10000, "").subscribe((data: any) => {
        this.projects = data.data;
        this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
        this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
      })
    } else {
      // if(this.otp == this.otpValue){
      if (this.isGoogleAuth) {

        this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {

          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();
          this.userLoggedIn = true;
          this.isProject = true;
          this.api.getProject({ user: res.data.id }, 1, 10000, "").subscribe((data: any) => {
            this.projects = data.data;
            this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
            this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
          })
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true);
        });
      } else {
        this.api.authenticateUser({ name: this.name, mobile: this.mobile, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {

          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();
          this.userLoggedIn = true;
          this.isProject = true;
          this.api.getProject({ user: res.data.id }, 1, 10000, "").subscribe((data: any) => {
            this.projects = data.data;
            this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
            this.projectName = (data.data.find((res: any) => { return res.isDefault }))?.title;
          })
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true);
        });
      }
      // }else{
      //   this.isLoading = false;
      //   this.showError("Incorrect OTP",true);
      // }
    }
  }

  calculate() {
    this.isLoading = true;
    if (!this.projectId) {
      this.isNext = true;
      this.isLoading = false;
      return;
    }
    Swal.fire({
      title: "Calculating...",
      icon: 'success',
      timer: 1500,
      allowOutsideClick: false,
      showConfirmButton: false
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        this.fsiCalculateData();
        if (!this.edit) {
          const data = JSON.stringify({
            rulesApplicability: this.rulesApplicability,
            plotArea: this.plotArea,
            plotAreaUnit: this.plotAreaUnit,
            deductionArea: this.deductionArea,
            deductionAreaUnit: this.deductionAreaUnit,
            widthRoad: this.widthRoad,
            widthRoadUnit: this.widthRoadUnit,
            gunthewariCertificate: this.gunthewariCertificate,
            sanctionedLayout: this.sanctionedLayout,
            myLandCost: this.myLandCost,
            openSpace: this.openSpace,
            redevelopmentProposal: this.redevelopementProposal,
            useBuilding: this.useBuilding,
            user: this.auth.currentUserValue.id,
            project: this.projectId,
            fullFsiFactor: this.fullFsiFactor,
            basicFsiFactor: this.basicFsiFactor,
            premiumFsiFactor: this.premiumFsiFactor,
            tdrFactor: this.tdrFactor,
            accessRoadWidthInMeter: this.accessRoadWidthInMeter,
            areaOfPlotSqm: this.areaOfPlotSqm,
            areaOfPlotSqft: this.areaOfPlotSqft,
            deductionRoadSqm: this.deductionRoadSqm,
            deductionRoadSqft: this.deductionRoadSqft,
            balanceAreaPlotSqm: this.balanceAreaPlotSqm,
            balanceAreaPlotSqft: this.balanceAreaPlotSqft,
            amenitySpaceRequired: this.amenitySpaceRequired,
            requiredAmenitySpaceSqm: this.requiredAmenitySpaceSqm,
            requiredAmenitySpaceSqft: this.requiredAmenitySpaceSqft,
            netAreaPlotSqm: this.netAreaPlotSqm,
            netAreaPlotSqft: this.netAreaPlotSqft,
            openSpaceRequired: this.openSpaceRequired,
            requiredOpenSpaceSqm: this.requiredOpenSpacePlaygroundSqm,
            requiredOpenSpaceSqft: this.requiredOpenSpacePlaygroundSqft,
            internalRoadAreaSqm: this.internalRoadAreaSqm,
            internalRoadAreaSqft: this.internalRoadAreaSqft,
            basicFsiSqm: this.basicFsiSqm,
            basicFsiSqft: this.basicFsiSqft,
            additionFsiSqm: this.additionFsiSqm,
            additionFsiSqft: this.additionFsiSqft,
            tdrFsiSqm: this.tdrFsiSqm,
            tdrFsiSqft: this.tdrFsiSqft,
            redevelopementPercent: this.redevelopementPercent,
            redevelopementFsiSqm: this.redevelopementFsiSqm,
            redevelopementFsiSqft: this.redevelopementFsiSqft,
            additionalFsiSqm: this.additionalFsiSqm,
            additionalFsiSqft: this.additionalFsiSqft,
            totalFsiSqm: this.totalFsiSqm,
            totalFsiSqft: this.totalFsiSqft,
            anciliaryFsiPercent: this.anciliaryFsiPercent,
            anciliaryFsiSqm: this.anciliaryFsiSqm,
            totalFsiIncludeAnciliaryFsiSqm: this.totalFsiIncludeAnciliaryFsiSqm,
            totalFsiIncludeAnciliaryFsiSqft: this.totalFsiIncludeAnciliaryFsiSqft,
            tdrFsi: this.tdrFsi,
            basicFsi: this.basicFsi,
            premiumFsi: this.premiumFsi,
          });

          this.api.createFsi(data).subscribe(res => {
            this.isLoading = false;
            this.closeModal.emit('close');
            this.isResult = true;
            this.selectedItem = res.data;
            // this.router.navigate(['/project',this.projectId]);
          })
          // }

        } else {
          const data = JSON.stringify({
            rulesApplicability: this.rulesApplicability,
            plotArea: this.plotArea,
            plotAreaUnit: this.plotAreaUnit,
            deductionArea: this.deductionArea,
            deductionAreaUnit: this.deductionAreaUnit,
            widthRoad: this.widthRoad,
            widthRoadUnit: this.widthRoadUnit,
            gunthewariCertificate: this.gunthewariCertificate,
            sanctionedLayout: this.sanctionedLayout,
            myLandCost: this.myLandCost,
            openSpace: this.openSpace,
            redevelopmentProposal: this.redevelopementProposal,
            useBuilding: this.useBuilding,
            fullFsiFactor: this.fullFsiFactor,
            basicFsiFactor: this.basicFsiFactor,
            premiumFsiFactor: this.premiumFsiFactor,
            tdrFactor: this.tdrFactor,
            accessRoadWidthInMeter: this.accessRoadWidthInMeter,
            areaOfPlotSqm: this.areaOfPlotSqm,
            areaOfPlotSqft: this.areaOfPlotSqft,
            deductionRoadSqm: this.deductionRoadSqm,
            deductionRoadSqft: this.deductionRoadSqft,
            balanceAreaPlotSqm: this.balanceAreaPlotSqm,
            balanceAreaPlotSqft: this.balanceAreaPlotSqft,
            amenitySpaceRequired: this.amenitySpaceRequired,
            requiredAmenitySpaceSqm: this.requiredAmenitySpaceSqm,
            requiredAmenitySpaceSqft: this.requiredAmenitySpaceSqft,
            netAreaPlotSqm: this.netAreaPlotSqm,
            netAreaPlotSqft: this.netAreaPlotSqft,
            openSpaceRequired: this.openSpaceRequired,
            requiredOpenSpaceSqm: this.requiredOpenSpacePlaygroundSqm,
            requiredOpenSpaceSqft: this.requiredOpenSpacePlaygroundSqft,
            internalRoadAreaSqm: this.internalRoadAreaSqm,
            internalRoadAreaSqft: this.internalRoadAreaSqft,
            basicFsiSqm: this.basicFsiSqm,
            basicFsiSqft: this.basicFsiSqft,
            additionFsiSqm: this.additionFsiSqm,
            additionFsiSqft: this.additionFsiSqft,
            tdrFsiSqm: this.tdrFsiSqm,
            tdrFsiSqft: this.tdrFsiSqft,
            redevelopementPercent: this.redevelopementPercent,
            redevelopementFsiSqm: this.redevelopementFsiSqm,
            redevelopementFsiSqft: this.redevelopementFsiSqft,
            additionalFsiSqm: this.additionalFsiSqm,
            additionalFsiSqft: this.additionalFsiSqft,
            totalFsiSqm: this.totalFsiSqm,
            totalFsiSqft: this.totalFsiSqft,
            anciliaryFsiPercent: this.anciliaryFsiPercent,
            anciliaryFsiSqm: this.anciliaryFsiSqm,
            totalFsiIncludeAnciliaryFsiSqm: this.totalFsiIncludeAnciliaryFsiSqm,
            totalFsiIncludeAnciliaryFsiSqft: this.totalFsiIncludeAnciliaryFsiSqft,
            tdrFsi: this.tdrFsi,
            basicFsi: this.basicFsi,
            premiumFsi: this.premiumFsi,
          });

          this.api.updateFsi(data, this.calId).subscribe(res => {
            this.isLoading = false;
            this.isResult = true;
            this.selectedItem = res.data;


            // this.router.navigate(['/project',this.projectId]);
          })
        }
      }
    });

  }

  navigate() {
    if (this.isModal) {
      this.closeModal.emit('close');
    } else {
      this.router.navigate(['/project', this.projectId])
    }
  }

  download() {
    this.printLoader = true;
    this.api.downloadFSI(this.selectedItem._id).subscribe(data => {
      const link = document.createElement('a');
      link.href = this.baseURL + '/file/retrieve-pdf/' + data.data;
      link.target = '_blank';
      link.download = 'FSI Calculator.pdf'; // Set the desired filename
      link.click();
      this.printLoader = false;
    })
  }
}
