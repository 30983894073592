import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SeoService } from '../core/services/seo.service';
import { FsiCalculatorComponent } from '../shared/fsi-calculator/fsi-calculator.component';
import { CommonModule, Location, isPlatformBrowser } from '@angular/common';
import { SideMarginComponent } from '../shared/side-margin/side-margin.component';
import { ApiService } from '../core/services/api.service';
import { ParkingComponent } from '../shared/parking/parking.component';
 

@Component({
  selector: 'app-standalone-calculators',
  standalone: true,
  imports:[CommonModule, FsiCalculatorComponent, SideMarginComponent, RouterModule, ParkingComponent],
  templateUrl: './standalone-calculators.component.html',
  styleUrls: ['./standalone-calculators.component.scss']
})
export class StandaloneCalculatorsComponent implements OnInit{

  type:any="";
  blogs: any = [];

  constructor(public route:ActivatedRoute,private titleService: Title, private seoService: SeoService, private metaService:Meta, public locationS :Location,
    @Inject(PLATFORM_ID) public platformId:Object, public api:ApiService, public router:Router){
    this.titleService.setTitle(this.seoService.getTitle("all-calculators"));
    this.metaService.addTags(this.seoService.getSEOMetaData("all-calculators"));
    this.type = this.locationS.path();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    
      const messagingElement = document.querySelector('.messaging');
      if (messagingElement) {
        messagingElement.classList.remove('hidden');
      }
    }
    this.api.getBlog({}, 1, 3, "").subscribe(data => {
      this.blogs = data.data;
    })
  }

  openBlog(item: any) {
    var convertedStr = item.title.replace(/\s/g, "-");

    this.router.navigate(['/blog/' + convertedStr]);
  }

}
