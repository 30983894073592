<div class="">
    <div class="flex flex-col {{isModal?'mt-10 ':'pt-10'}}">
        <div class="flex-auto flex justify-center mb-2">
            <h3 class="flex-none text-xl tracking-wider text-white font-semibold">
                {{(!isResult)?'Parking Calculator':'Parking Calculator Result'}}</h3><br>

        </div>
        <!-- <div class="flex-auto flex justify-center mb-4">
          <p class="flex items-center text-xs tracking-wider text-white font-semibold leading-4">
            {{serviceDetails.category}}
            <i class="fa-solid fa-chevron-right mx-1"></i>
            {{serviceDetails.displayText}}
          </p>
      
        </div> -->

    </div>
    <div class=" flex-auto flex justify-center {{isModal?'m-10 mt-5':'h-screen'}}" *ngIf="!isResult">
        <div
            class="relative bg-white shadow-md {{isModal?'w-full h-[400px]':'w-4/5 lg:w-2/3 xl:w-6/12 h-4/6'}} overflow-auto rounded-3xl">
            <div class="m-5 h-4/5 overflow-auto">


                <div [class]="(currentStep !== 1)?'hidden':''">
                    <div>
                        <div class="grid md:grid-cols-1 grid-cols-1 gap-x-2">
                            <div class="relative rounded-md  mb-3 ">
                                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Planning Authority <span class="text-red-600">*</span></p>
                                <div class="border rounded" [class]="(isNext && planningAuthority == '')?'border-red-500':'border-gray-300'">
                                    <select [(ngModel)]="planningAuthority"
                                        class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                                        <option value="Pune Municipal Corporation">Pune Municipal Corporation</option>
                                        <option value="Thane Municipal Corporation">Thane Municipal Corporation</option>
                                        <option value="Pimpri Chinchwad Municipal Corporation">Pimpri Chinchwad Municipal Corporation</option>
                                        <option value="Nagpur Municipal Corporation">Nagpur Municipal Corporation</option>
                                        <option value="Nagpur TOD">Nagpur TOD</option>
                                        <option value="Nasik Municipal Corporation">Nasik Municipal Corporation</option>
                                        <option value="Municipal Corporation in MMR Area (Except Thane)">Municipal Corporation in MMR Area (Except Thane)</option>
                                        <option value="Any Other A,B,C Class Municipal Corporation">Any Other A,B,C Class Municipal Corporation</option>
                                        <option value="Area Development Authority">Area Development Authority</option>
                                        <option value="SPA (Special Planning Authority)">SPA (Special Planning Authority)</option>
                                        <option value="D Class Municipal Corporation">D Class Municipal Corporation</option>
                                        <option value="A Class Municipal Council">A Class Municipal Council</option>
                                        <option value="B, C Class Municipal Council">B, C Class Municipal Council</option>
                                        <option value="Nagar Panchayat">Nagar Panchayat</option>
                                        <option value="Any Other Non-Municipal Town / Regional Plan Area">Any Other Non-Municipal Town / Regional Plan Area</option>
                                    </select>
                                </div>
                            </div>

                            <div class="relative rounded-md  mb-3 ">
                                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Select Locality <span class="text-red-600">*</span></p>
                                <div class="border rounded" [class]="(isNext && locality == '')?'border-red-500':'border-gray-300'">
                                    <select [(ngModel)]="locality"
                                        class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                                        <option value="Conjested Area (Gaothan)">Conjested Area (Gaothan)</option>
                                        <option value="Non-Conjested Area">Non-Conjested Area</option>
                                    </select>
                                </div>
                            </div>

                            <div class="relative rounded-md  mb-3 ">
                                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Select Special Zones <span class="text-red-600">*</span></p>
                                <div class="border rounded" [class]="(isNext && specialZone == '')?'border-red-500':'border-gray-300'">
                                    <select [(ngModel)]="specialZone"
                                        class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                                        <option value="Regular Zone">Regular Zone</option>
                                        <option value="TOD Zone - Pune, PCMC, PMRDA">TOD Zone - Pune, PCMC, PMRDA</option>
                                        <option value="TOD Zone - Nagpur">TOD Zone - Nagpur</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div> -->
                <div [class]="(currentStep !== 2)?'hidden':''">
                    <legend class="text-base mb-2 font-bold tracking-wide text-slate-700">Select all uses</legend>
                    <div>
                        <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border" *ngFor="let item of uses; let i = index">
                            <div class="overflow-x-auto">
                                <table class="min-w-full indent-0 border-inherit border-collapse ">
                                    <tbody>
                                        <tr class="">
                                            <th
                                                class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                                Use {{i+1}} <span class="text-red-600">*</span></th>
                                            <td colspan="2"
                                                class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                                <div class="border rounded"
                                                    [class]="(isNext && item.use == '')?'border-red-500':'border-gray-300'">
                                                    <select [(ngModel)]="item.use" (change)="changeUse(item)"
                                                        class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                                                        <option value="" disabled>Select Use</option>
                                                        <option value="Residential Units">Residential Units</option>
                                                        <option value="Commercial Use">Commercial Use</option>
                                                        <option *ngIf="specialZone != 'TOD Zone - Nagpur'" value="Hotel">Hotel</option>
                                                        <option *ngIf="specialZone != 'TOD Zone - Nagpur'" value="Educational">Educational</option>
                                                        <option *ngIf="specialZone != 'TOD Zone - Nagpur'" value="Medical Institutions">Medical Institutions</option>
                                                        <option *ngIf="specialZone != 'TOD Zone - Nagpur'" value="Other Building">Other Building</option>
                                                    </select>
    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                                Sub Type<span class="text-red-600">*</span></th>
                                            <td colspan="2"
                                                class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                                <div class="border rounded"
                                                    [class]="(isNext && item.subType == '')?'border-red-500':'border-gray-300'">
                                                    <select [(ngModel)]="item.subType" (change)="getUnitText($event,item)"
                                                        class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                                                        <option value="" disabled>Select SubType</option>
                                                        <option value="0 to 30 SqM (0-322 SqFt)" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Residential Units'">0 to 30 SqM (0-322 SqFt)</option>
                                                        <option value="0 to 30 SqM (0-322 SqFt)" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Residential Units'">0 to 30 SqM (0-322 SqFt)</option>
                                                        <option value="30 to 40 SqM (320-430 SqFt)" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Residential Units'">30 to 40 SqM (320-430 SqFt)</option>
                                                        <option value="40 to 80 SqM (430-860 SqFt)" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Residential Units'">40 to 80 SqM (430-860 SqFt)</option>
                                                        <option value="80 to 150 SqM (860-1615 SqFt)" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Residential Units'">80 to 150 SqM (860-1615 SqFt)</option>
                                                        <option value="Above 150 SqM (>1615 SqFt)" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Residential Units'">Above 150 SqM (>1615 SqFt)</option>
                                                        <option value="Shops" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Shops</option>
                                                        <option value="Shops - Whole Sale Only" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Shops - Whole Sale Only</option>
                                                        <option value="Hazardous Building" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Hazardous Building</option>
                                                        <option value="Offices" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Offices</option>
                                                        <option value="IT building Offices" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">IT building Offices</option>
                                                        <option value="Storage - Any Type" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Storage - Any Type</option>
                                                        <option value="Data Centre" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Data Centre</option>
                                                        <option value="Private Business Building" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Private Business Building</option>
                                                        <option value="Hotel - Guest Rooms" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Hotel'">Hotel - Guest Rooms</option>
                                                        <option value="Hotel - Dinning Area" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Hotel'">Hotel - Dinning Area</option>
                                                        <option value="School - Admin Area" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Educational'">School - Admin Area</option>
                                                        <option value="School - Classrooms" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Educational'">School - Classrooms</option>
                                                        <option value="College - Admin Area" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Educational'">College - Admin Area</option>
                                                        <option value="College - Classrooms" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Educational'">College - Classrooms</option>
                                                        <option value="Any Coaching Classes" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Educational'">Any Coaching Classes</option>
                                                        <option value="Health Club / Clinic" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Medical Institutions'">Health Club / Clinic</option>
                                                        <option value="Maternity Homes" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Medical Institutions'">Maternity Homes</option>
                                                        <option value="Hospital" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Medical Institutions'">Hospital</option>
                                                        <option value="Any Other Medical Building" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Medical Institutions'">Any Other Medical Building</option>
                                                        <option value="Government / Semi Public Building" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Other Building'">Government / Semi Public Building</option>
                                                        <option value="Industrial Building" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Other Building'">Industrial Building</option>
                                                        <option value="Theatres, Auditoriums" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Other Building'">Theatres, Auditoriums</option>
                                                        <option value="Multiplexes" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Other Building'">Multiplexes</option>
                                                        <option value="Mangal Karyalaya / Marriage Halls, Cultural Halls and Banquet Hall" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Other Building'">Mangal Karyalaya / Marriage Halls, Cultural Halls and Banquet Hall</option>
                                                        <option value="Community Hall" *ngIf="specialZone != 'TOD Zone - Nagpur' && item.use == 'Other Building'">Community Hall</option>
                                                        <option value="25 to 40 SqM (269-430 SqFt)" *ngIf="specialZone == 'TOD Zone - Nagpur' && item.use == 'Residential Units'">25 to 40 SqM (269-430 SqFt)</option>
                                                        <option value="40 to 60 SqM (460-645 SqFt)" *ngIf="specialZone == 'TOD Zone - Nagpur' && item.use == 'Residential Units'">40 to 60 SqM (460-645 SqFt)</option>
                                                        <option value="60 to 80 SqM (645-861 SqFt)" *ngIf="specialZone == 'TOD Zone - Nagpur' && item.use == 'Residential Units'">60 to 80 SqM (645-861 SqFt)</option>
                                                        <option value="Above 80 SqM (>861 SqFt)" *ngIf="specialZone == 'TOD Zone - Nagpur' && item.use == 'Residential Units'">Above 80 SqM (>861 SqFt)</option>
                                                        <option value="Private Business Building" *ngIf="specialZone == 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Private Business Building</option>
                                                        <option value="Government / Semi Public Building" *ngIf="specialZone == 'TOD Zone - Nagpur' && item.use == 'Commercial Use'">Government / Semi Public Building</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="item.subType">
                                            <th
                                                class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                               Unit Text<span class="text-red-600">*</span>
                                            </th>
                                            <td
                                                class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                                <div class="border rounded"
                                                    [class]="(isNext && item.unitText == '')?'border-red-500':'border-gray-300'">
                                                    <input [(ngModel)]="item.unitText" type="text" readonly
                                                        class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                                Quantity<span class="text-red-600">*</span>
                                            </th>
                                            <td
                                                class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                                <div class="flex border rounded" [class]="(isNext && item.quantity == '')?'border-red-500':'border-gray-300'">
                                                    <div class="flex-1">
                                                        <input [(ngModel)]="item.quantity" type="number" name="price"
                                                        id="price"
                                                        class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                                                    </div>
                                                    <div>
                                                      <select [(ngModel)]="item.quantityUnit"
                                                        class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                                                        <option value="Sq. M.">Sq. M.</option>
                                                        <option value="Sq. Ft.">Sq. Ft.</option>
                                                      </select>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="float-left" *ngIf="i != 0">
                                <a class="text-red-500 cursor-pointer text-xs hover:text-red-700 ms-2 mb-2 border-red-500 block border rounded-lg py-1 px-2" (click)="deleteUse(i)"><i class="fa-solid fa-trash me-1"></i>Delete Use</a>
                            </div>
                        </div>
                        <div class="float-right">
                            <a class="text-primary cursor-pointer text-sm hover:text-blue-700 border-primary border rounded-lg py-1 px-2" (click)="addUse()"><i class="fa-solid fa-plus me-1"></i>Add Another Use</a>
                        </div>
                    </div>
                </div>
                <div *ngIf="currentStep == 3 && !isProject && !isGoogleAuth">
                    <legend class="text-base  font-bold tracking-wide text-slate-700">Fill this details to get free
                        quotes from
                        partners</legend>
                    <div class="w-full mt-3">
                        <div class="grid w-full">
                            <div class="relative rounded-md  mb-3">
                                <p class="text-sm text-left tracking-wide text-slate-700">Mobile</p>
                                <input type="text" [disabled]="userLoggedIn" (keyup)="mobileCheck($event)"
                                    (input)="onMobileInput($event)" maxlength="10" pattern="[0-9]{10}"
                                    [(ngModel)]="mobile" name="mobile" focuse="true" id="mobile"
                                    class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    placeholder="">
                                <p class="text-slate-500 text-xs flex items-center mt-1">
                                    <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
                                    <span class="ml-1">Your number is secure as per our <a
                                            href="/company/privacy-policy" target="_blank">Privacy Policy</a></span>
                                </p>

                            </div>

                            <div class="relative rounded-md  mb-3 " *ngIf="isSendOTP">
                                <p class="text-sm text-left tracking-wide text-slate-700">Name</p>
                                <input type="text" [disabled]="userLoggedIn" [(ngModel)]="name" name="name"
                                    focuse="true" id="name" [class]="(isNext && name == '')?'ring-red-500':''"
                                    class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    placeholder="">
                            </div>

                            <div class="relative rounded-md  mb-3 " *ngIf="isSendOTP">
                                <p class="text-sm text-left tracking-wide text-slate-700">Email</p>
                                <input type="text" [disabled]="userLoggedIn" [(ngModel)]="email" name="name"
                                    focuse="true" id="name" [class]="(isNext && email == '')?'ring-red-500':''"
                                    class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    placeholder="">
                            </div>

                            <div class="relative rounded-md mb-3 grid justify-center" *ngIf="isOTP">
                                <legend
                                    class="text-sm text-center font-semibold tracking-wide text-slate-700 my-3 mb-0">
                                    Enter OTP
                                </legend>
                                <p class="text-slate-500 text-xs text-center mt-0">
                                    <span class="ml-1">OTP sent on text sms</span>
                                </p>
                                <ng-otp-input (onInputChange)="otpEnterd($event)"
                                    [config]="{length:6,allowNumbersOnly:true}">
                                </ng-otp-input>
                            </div>
                        </div>
                        <div aria-label="Toast"
                            class="mt-6 mx-auto bg-red-100 border border-red-200 rounded-md relative" *ngIf="error">
                            <div class="p-4 rounded-md">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true" class="nu rw ayv">
                                            <path fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <div class="ms-3">
                                        <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There
                                            were some errors with
                                            your submission</h3>
                                        <p class="font-normal text-sm leading-5 tracking-wider text-red-600">
                                            {{errorMessage}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearError()"
                                *ngIf="errorCancelAble"><i class="fa-solid fa-xmark text-orange-600"></i></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="currentStep == 3 && !isProject && isGoogleAuth">
                    <legend class="text-base  font-bold tracking-wide text-slate-700">Fill this details to get free
                        quotes from
                        partners</legend>
                    <div class="w-full mt-3">
                        <div class="grid w-full">
                            <div class="relative rounded-md  mb-3">
                                <p class="text-sm text-left tracking-wide text-slate-700">Mobile</p>
                                <input type="text" [disabled]="userLoggedIn" (keyup)="mobileCheckModal($event)"
                                    (input)="onMobileInputModal($event)" maxlength="10" pattern="[0-9]{10}"
                                    [(ngModel)]="mobile" name="mobile" focuse="true" id="mobile"
                                    class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                                    placeholder="">
                                <p class="text-slate-500 text-xs flex items-center mt-1">
                                    <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
                                    <span class="ml-1">Your number is secure as per our <a
                                            href="/company/privacy-policy" target="_blank">Privacy Policy</a></span>
                                </p>

                            </div>

                            <div class="relative rounded-md mb-3 grid justify-center" *ngIf="isOTPModal">
                                <legend
                                    class="text-sm text-center font-semibold tracking-wide text-slate-700 my-3 mb-0">
                                    Enter OTP
                                </legend>
                                <p class="text-slate-500 text-xs text-center mt-0">
                                    <span class="ml-1">OTP sent on text sms</span>
                                </p>
                                <ng-otp-input (onInputChange)="otpEnterd($event)"
                                    [config]="{length:6,allowNumbersOnly:true}">
                                </ng-otp-input>
                            </div>
                        </div>
                        <div aria-label="Toast"
                            class="mt-6 mx-auto bg-red-100 border border-red-200 rounded-md relative"
                            *ngIf="errorModal">
                            <div class="p-4 rounded-md">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true" class="nu rw ayv">
                                            <path fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <div class="ms-3">
                                        <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There
                                            were some errors with
                                            your submission</h3>
                                        <p class="font-normal text-sm leading-5 tracking-wider text-red-600">
                                            {{errorMessageModal}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearErrorModal()"
                                *ngIf="errorCancelAbleModal"><i class="fa-solid fa-xmark text-orange-600"></i></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="currentStep == 3  && isProject">
                    <legend class="text-sm text-left font-bold tracking-wide text-slate-700">Select Project</legend>
                    <div class="mt-2 space-y-6 mb-2">
                        <ng-container>
                            <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-3">
                                @if(projects.length > 0){
                                <div *ngFor="let option of projects; let i = index" class="bg-[#F5F5F5] border rounded"
                                    [class]="(isNext && !projectId)? 'border-red-500':'border-[#aaa]'"
                                    [ngClass]="{'py-1 px-2': (option.editProjects), 'p-2': (!option.editProjects) }">
                                    <div class="flex items-center gap-x-3" *ngIf="!option.editProjects">
                                        <input [id]="i" type="radio" [checked]="option.isDefault"
                                            (change)="getProjectName($event,false)"
                                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                                            [(ngModel)]="projectId" [value]="option._id" [name]="'question' + i">

                                        <label [for]="i"
                                            class="cursor-pointer block w-full text-sm font-medium leading-6 text-gray-900">{{
                                            option.title }}</label>
                                        <i class="fa-regular fa-pen-to-square" (click)="editProject(option,i)"></i>
                                    </div>
                                    <div class="flex" *ngIf="option.editProjects">
                                        <input type="text" id="input{{i}}"
                                            class="rounded-none rounded-s-md  bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            [(ngModel)]="option.title" placeholder="Enter Project Name">
                                        <span (click)="editProjectName(option)"
                                            class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                            <i class="fa-solid fa-check"
                                                style="margin: auto; color: #4972ef; font-size: larger;"></i>
                                        </span>
                                    </div>
                                </div>
                                }
                                <div>
                                    <div class="flex items-center gap-x-3" *ngIf="!Projects">
                                        <label
                                            class="cursor-pointer block  text-sm font-medium leading-6 text-gray-900 bg-[#F5F5F5] rounded p-2 w-full text-center"
                                            style="border: 1px solid blue;" (click)="AddProject()"><i
                                                class="fa-solid fa-plus me-2"></i>Add
                                            Project</label>
                                    </div>

                                    <div class="grid grid-row-2 items-center gap-x-3 bg-[#F5F5F5] border rounded-lg"
                                        [ngClass]="{'py-1 px-2': (Projects), 'p-2': (!Projects) }" *ngIf="Projects"
                                        style="border: 1px solid gray;">
                                        <div class="flex" *ngIf="Projects">
                                            <input type="text"
                                                class="rounded-none rounded-s-md  bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                [(ngModel)]="newProjectName" placeholder="Enter Project Name">
                                            <span (click)="createdNewProject()" *ngIf="!newProjectStatus"
                                                class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                <i class="fa-solid fa-check"
                                                    style="margin: auto; color: #4972ef; font-size: larger;"></i>
                                            </span>
                                            <span *ngIf="newProjectStatus"
                                                class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                <div role="status">
                                                    <svg aria-hidden="true"
                                                        class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                        viewBox="0 0 100 101" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                            fill="currentFill" />
                                                    </svg>
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <p class="text-red-600 text-xs">You can also rename of projects from “All projects” tab in Menu
                            bar</p>
                    </div>
                </div>

            </div>


            <div class="absolute inset-x-0 bottom-0 bg-white">
                <div class="flex gap-x-2 mx-5 my-2">
                    <button [disabled]="isLoading" *ngIf="currentStep != 1" (click)="backStep()"
                        class="flex-none  w-1/6 p-1.5 rounded border-slate-400 bg-slate-200 hover:bg-slate-300 text-slate-700 text-center border"><i
                            class="fa-solid fa-chevron-left"></i></button>
                    <ng-container *ngIf="projectId">
                        <button [disabled]="isLoading"
                            (click)="currentStep == 1 ? nextStep() : (!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3) ? sendOTP() : onSubmit()"
                            [class]="(currentStep==0) ? 'w-full':'w-full'"
                            class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
                            <!-- <ng-container *ngIf="currentStep < questions.length">Next</ng-container>
                  <ng-container *ngIf="!isOTP && !userLoggedIn && !isProject && currentStep == questions.length">Send OTP</ng-container>
                    <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP))">Submit</ng-container> -->

                            <ng-container *ngIf="currentStep == 1">Next</ng-container>
                            <ng-container
                                *ngIf="!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3">Send
                                OTP</ng-container>
                            <ng-container
                                *ngIf="!isOTP && !isOTPModal && userLoggedIn && !isProject && currentStep == 3">Submit</ng-container>
                            <ng-container *ngIf="currentStep == 2">{{(!edit)?'Submit':'Update'}}</ng-container>
                            <ng-container
                                *ngIf="(isOTP || isOTPModal) && userLoggedIn && isProject && currentStep == 3">Submit</ng-container>
                            <ng-container
                                *ngIf="!isOTP && !isOTPModal && userLoggedIn && isProject&&  currentStep == 3">Submit</ng-container>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!projectId">
                        <button [disabled]="isLoading"
                            (click)="currentStep == 1 ? nextStep() : (!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3) ? sendOTP() : ((isOTP || isOTPModal || userLoggedIn) && !isProject && currentStep == 3) ? nextSlide() :onSubmit()"
                            [class]="(currentStep==0) ? 'w-full':'w-full'"
                            class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
                            <!-- <ng-container *ngIf="currentStep < questions.length">Next</ng-container>
                  <ng-container *ngIf="!isOTP && !userLoggedIn && !isProject && currentStep == questions.length">Send OTP</ng-container>
                    <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP) && isProject)">Submit</ng-container>
                  <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP) && !isProject)">Next</ng-container> -->

                            <ng-container *ngIf="currentStep == 1">Next</ng-container>
                            <ng-container
                                *ngIf="!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3">Send
                                OTP</ng-container>
                            <ng-container
                                *ngIf="currentStep == 2 && ((userLoggedIn || isOTP || isOTPModal) && isProject)">
                                {{(!edit)?'Submit':'Update'}}</ng-container>
                            <ng-container
                                *ngIf="currentStep == 2 && ((userLoggedIn || isOTP || isOTPModal) && !isProject)">Next</ng-container>
                            <ng-container
                                *ngIf="currentStep == 2 && ((!userLoggedIn && !isOTP && !isOTPModal) && !isProject)">Next</ng-container>
                            <ng-container
                                *ngIf="currentStep == 3 && ((userLoggedIn || isOTP || isOTPModal) && isProject)">Submit</ng-container>
                            <ng-container
                                *ngIf="currentStep == 3 && ((userLoggedIn || isOTP || isOTPModal) && !isProject)">Next</ng-container>
                        </button>
                    </ng-container>
                    <h3 class="text-center my-3" *ngIf="!userLoggedIn && currentStep == 3 && !isGoogleAuth">OR</h3>
                    <asl-google-signin-button type="standard" *ngIf="!userLoggedIn && currentStep == 3 && !isGoogleAuth"
                        text="signin_with" logo_alignment="center" size='medium'></asl-google-signin-button>
                    <!--  -->
                </div>
            </div>

        </div>

    </div>
    <div class="flex-auto flex justify-center {{isModal?'m-10 mt-5':'h-screen'}}" *ngIf="isResult">
        <div
            class="relative bg-white shadow-md {{isModal?'w-full h-[400px]':'w-4/5 lg:w-2/3 xl:w-6/12 h-4/6'}} overflow-auto rounded-3xl">
            <div class="m-5 h-4/5 overflow-auto">
                <div class="px-4 py-6">
                    <div class="mt-3">
                        <span class="tracking-wider text-lg font-semibold">Calculations</span>
                        <button
                            class="w-auto float-right text-sm px-1.5 rounded my-1 font-normal border-amber-600 text-amber-600 hover:bg-amber-600 hover:text-white text-center border"
                            (click)="openCalculatorModal()">Edit</button>
                        <button
                            class="w-auto sm:float-right text-sm px-1.5 rounded my-1 me-2 font-normal border-primary text-primary hover:bg-primary hover:text-white text-center border"
                            (click)="parkingDownload()">Download</button>
                    </div>

                    <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
                        <div class="overflow-x-auto">
                            <table class="min-w-full indent-0 border-inherit border-collapse ">
                                <thead>
                                    <tr class="border-b-gray-300 border-b">
                                        <th
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            Use Type</th>
                                        <th
                                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            No of Units/ Area</th>
                                        <th
                                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            Cars</th>
                                        <th
                                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                            Scooter</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="border-b-gray-300 border-b" *ngFor="let item of selectedItem.uses">
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            {{item.subType}}</td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            {{item.quantity}} {{item.quantityUnit}}</td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            {{item.car}}</td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                            {{item.scooter}}
                                        </td>
                                    </tr>
                                    <tr class="border-b-gray-300 border-b">
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            </td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            </td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            {{selectedItem.totalCar}}</td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                            {{selectedItem.totalScooter}}
                                        </td>
                                    </tr>
                                    <tr class="border-b-gray-300 border-b">
                                        <td colspan="2"
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            Visitor's Parking
                                            </td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            {{selectedItem.totalVisitorCar}}</td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                            {{selectedItem.totalVisitorScooter}}
                                        </td>
                                    </tr>
                                    <tr class="border-b-gray-300 border-b">
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            </td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                           </td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            {{selectedItem.grandTotalCar}}</td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                            {{selectedItem.grandTotalScooter}}
                                        </td>
                                    </tr>
                                    <tr class="border-b-gray-300 border-b" *ngIf="selectedItem.specialZone == 'TOD Zone - Pune, PCMC, PMRDA'">
                                        <td colspan="2"
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            50% for TOD Zone</td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                                            {{selectedItem.metroCar}}</td>
                                        <td
                                            class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                                            {{selectedItem.metroScooter}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="absolute inset-x-0 bottom-0 bg-white">
                    <div class="text-center gap-x-2 mx-5 my-2 border-b">

                        <button (click)="navigate()"
                            class="w-auto py-1.5 px-3 me-2 rounded my-1 border-primary hover:bg-primary hover:text-white font-normal text-sm text-primary text-center border">
                            Visit {{projectName}}</button>
                        <button (click)="parkingDownload()"
                            class="w-auto py-1.5 px-3 me-2 rounded my-1 border-[#6B8FFA] hover:bg-[#4d77f7] hover:text-white font-normal text-sm text-[#6B8FFA] text-center border">
                            Download</button>

                        <button (click)="openCalculatorModal()"
                            class="w-auto py-1.5 px-3 rounded my-1 border-[#6B8FFA] hover:bg-[#4d77f7] hover:text-white font-normal text-sm text-[#6B8FFA] text-center border">
                            Edit</button>
                        <!--  -->
                    </div>
                    <div class="text-center my-2">
                        <p class="text-sm">Other Calculators</p>
                        <div class="grid md:flex justify-evenly">
                            <a class="text-primary cursor-pointer text-xs" [routerLink]="['/fsi-calculator']">FSI</a>
                            <a class="text-primary cursor-pointer text-xs" [routerLink]="['/side-margin-calculator']">Side Margin</a>
                            <a class="text-primary cursor-pointer text-xs">Developement Charges</a>
                            <a class="text-primary cursor-pointer text-xs">Water Requirement</a>
                            <a class="text-primary cursor-pointer text-xs">TDR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>